import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import "./index.scss";
import { useTranslation } from "react-i18next";
import CoinImg from '../../assets/images/act_coin.png'
import { sendAnalytic } from "../../utils/analytic";

function AppAnnuity(props) {
  const { setShowAirDrop, isAirDrop } = props

  const below960 = useMedia("(max-width: 960px)");
  const { t, i18n } = useTranslation();
  const history = useHistory()

  return (
    // 币价险活动
    <div className="app_annuity_box">
      <div
        className={["app_annuity"].join(' ')}
        onClick={(e) => {
          sendAnalytic("promotion_enter", { account: "", action: 'Top Banner' })
          history.push('/cryptoActivity');
        }}>

        <div className="text2">
          <div className="text1">{t('币价险重磅上线')}</div>
          <div className="text2_top">
            {
              below960 ?
                <img src={CoinImg} /> : ''
            }
            $ 1,000,000
            {
              !below960 ?
                <img src={CoinImg} /> : ''
            }
            <div className="text2_bot">{t('in Rewards and Benefits')}</div>
          </div>

        </div>
        <div className="text3_box">
          {
            i18n.language == 'EN' ?
              <>

                <div className="text3">{t('回馈幸运的您')}</div>
                <div className="text3">{t('你亏损我100%买单')}</div>
              </> :

              <div className="text3">{t('回馈幸运的您')} {t('你亏损我100%买单')}</div>
          }
        </div>
      </div>
    </div>
  );
}

export default AppAnnuity;
