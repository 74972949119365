import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import IPSTABI from "./abi/IPSTABI";

import { nToBN, bNToN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class IpstService extends Erc20Service {
  web3;
  ipstContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.ipstContract = new this.web3.eth.Contract(
        IPSTABI,
        networkConfig.lpToken.address
      );
    } catch (e) {
      console.log(e.message);
    }
  }



  // 查询ipst余额
  async getBalanceOf() {
    const account = await this.getCurrentAccount();
    try {
      const res = await this.ipstContract.methods.balanceOf(account).call();
      return bNToN(res, networkConfig.lpToken.decimals)
    } catch (err) {
      console.log(err);
      return {};
    }
  }


  // 查询总ipst
  async getTotalSupply() {
    try {
      const res = await this.ipstContract.methods.totalSupply().call();
      return bNToN(res, networkConfig.lpToken.decimals)
    } catch (err) {
      console.log(err);
      return {};
    }
  }
}

export default IpstService;
