import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "@metamask/legacy-web3";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import store from "./store/index";
import "./i18n";
import { ConfigProvider } from 'antd';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import dayjs from "dayjs";
import { message } from 'antd';
import 'antd/dist/antd.css';
import "./index.css";
// import 'dayjs/locale/zh-cn';
import 'moment/locale/zh-cn'

// 动画
// import './animation/animate.css'
// import './animation/all.min'
// rem 插件
import 'lib-flexible'
// import Vconsole from 'vconsole'
// new Vconsole()


message.config({
  maxCount: 1 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});


function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
}

ReactDOM.render(
  // <React.StrictMode>
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <ConfigProvider>
        {/* <ConfigProvider locale={zhCN}> */}
        <App />
      </ConfigProvider>
    </Provider>
  </Web3ReactProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
