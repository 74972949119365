import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import NEIBondABI from "./abi/NEIBondABI";
import { nToBN, bNToN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class NeiBondService extends Erc20Service {
  web3;
  neiBondContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.neiBondContract = new this.web3.eth.Contract(
        NEIBondABI,
        networkConfig.neiBondAddr
      );
    } catch (e) {
      console.log(e.message);
    }
  }



  // 查询
  async getValue(count, tokenPrice, tokenAddress) {
    try {
      let amount
      const decimals = await this.getTokenDecimals(tokenAddress);
      amount = nToBN(count, decimals); // 金额
      const getValue = await this.neiBondContract.methods.getValue(tokenAddress, amount, tokenPrice).call();
      return getValue
    } catch (err) {
      console.log(err);
      return {};
    }
  }

  // 根据代币地址，查询存储新债券的条款信息
  // principal = tokenAddress 代币地址
  // 分发时间 vestingTerm * 3
  async getTerms(tokenAddress, tid) {
    try {
      // const decimals = await this.getTokenDecimals(tokenAddress);
      const terms = await this.neiBondContract.methods.terms(tokenAddress, tid).call();
      const remainingAmount = bNToN(terms.issueNumber - terms.haveSell, networkConfig.nedToken.decimals);
      return {
        fee: terms.fee,
        haveSell: terms.haveSell,
        isLP: terms.isLP,
        issueNumber: terms.issueNumber,
        maxAmount: bNToN(terms.maxAmount, networkConfig.nedToken.decimals),
        minAmount: bNToN(terms.minAmount, networkConfig.nedToken.decimals),
        period: terms.period,
        sellRate: terms.sellRate,
        remainingAmount
      }


      // {
      //   controlVariable: terms.controlVariable, // 价格控制变量
      //     vestingTerm: terms.period, // 分发时长（区块）
      //       minimumPrice: terms.minPrice, //最小价格
      //         maxPayout: terms.maxPayout, //最大支付比率
      //           fee: terms.fee, // 手续费比率
      //             maxDebt: bNToN(terms.maxDebt, networkConfig.nedToken.decimals) // 最大债务支付额度
      // };
    } catch (err) {
      console.log(err);
      return {};
    }
  }

  async getIsNeedSig(tokenAddress, tid) {
    try {
      // const principal = tokenAddress; // 代币地址
      const isNeed = await this.getTerms(tokenAddress, tid)
      return isNeed.isLP
    } catch (err) {
      console.log(err);
      return;
    }
  }


  // 查询当前时间戳
  async getCurrTime() {
    try {
      const currTime = await this.neiBondContract.methods
        .getCurrTime()
        .call();
      return currTime;
    } catch (e) {
      console.log(e);
      return;
    }
  }
  // 根据用户地址、代币地址，查询用户债券信息
  // 获取剩余的分发时间 vesting * 3
  async getBondInfo(tokenAddress, tid) {
    const account = await this.getCurrentAccount();
    try {
      const bondInfo = await this.neiBondContract.methods
        .bondInfo(account, tokenAddress, tid)
        .call();
      return {
        payout: bNToN(bondInfo.payout, networkConfig.nedToken.decimals), // 应给的NEI额度
        vesting: bondInfo.period, // 剩余释放时长（区块） BSC主网3秒一个区块
        lastBlock: bondInfo.lastBlock, // 上一次支付时间
        pricePaid: bondInfo.pricePaid // 价格，每次购买价格都会更新
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }
  // 获取债券用户可购买基数
  async getReserveRate() {
    try {
      const res = await this.neiBondContract.methods
        .reserveRate()
        .call();
      return bNToN(res, 9);
    } catch (err) {
      console.log(err);
      return 0;
    }
  }

  // 查询剩余分发区块
  // 获取剩余的分发时间 vesting * 3 (ropsten:15, BSC:3)
  async getLeftBlock(tokenAddress, tid) {
    const account = await this.getCurrentAccount();
    try {
      const principal = tokenAddress;
      const LeftBlock = await this.neiBondContract.methods
        .getLeftBlock(account, tokenAddress, tid)
        .call();
      return LeftBlock;
    } catch (err) {
      console.log(err);
      return 0;
    }
  }

  // 校验参数
  async validate(info, hash, srv) {
    try {
      const result = await this.neiBondContract.methods
        .validate(
          info,
          hash,
          srv
        )
        .call();
      return result;
    } catch (err) {
      let code = 100;
      if (err.message.indexOf("execution reverted: Invalid address") > -1) {
        code = 1;
      } else if (
        err.message.indexOf("execution reverted: Max capacity reached") > -1
      ) {
        code = 2;
      } else if (
        err.message.indexOf("execution reverted: more than max price") > -1
      ) {
        code = 3;
      } else if (
        err.message.indexOf("execution reverted: Bond too small") > -1
      ) {
        code = 4;
      } else if (
        err.message.indexOf("execution reverted: Bond too large") > -1
      ) {
        code = 5;
      }

      return {
        code
      };
    }
  }
  // 效验取值
  // 验证购买的值
  async checkDepositAmount(
    amount,
    tokenAddress,
    tid,
    bindTokenToMarq,
  ) {
    const account = await this.getCurrentAccount();
    try {
      // const decimals = await this.getTokenDecimals(tokenAddress);
      amount = nToBN(amount, 18);
      const result = await this.neiBondContract.methods
        .checkValue(
          amount,
          account,
          tokenAddress,
          tid,
          bindTokenToMarq
        )
        .call();
      return result;
    } catch (err) {
      let code = 100;
      if (err.message.indexOf("execution reverted: Invalid address") > -1) {
        code = 1;
      } else if (
        err.message.indexOf("execution reverted: Max capacity reached") > -1
      ) {
        code = 2;
      } else if (
        err.message.indexOf("execution reverted: more than max price") > -1
      ) {
        code = 3;
      } else if (
        err.message.indexOf("execution reverted: Bond too small") > -1
      ) {
        code = 4;
      } else if (
        err.message.indexOf("execution reverted: Bond too large") > -1
      ) {
        code = 5;
      }

      return {
        code
      };
    }
  }

  // 存款债券,抵押稳定币到国库，同时计算支付的债券以及给DAO的费用
  // 常规稳定币购买(不需要签名购买)
  // async deposit(tokenAddress, amount, tid) {
  async deposit(info, sig) {
    const account = await this.getCurrentAccount();
    try {
      // const principal = tokenAddress; // 代币地址

      // const decimals = await this.getTokenDecimals(tokenAddress);
      // amount = nToBN(amount, decimals); // 金额

      // const depositor = account; // 受益地址



      const res = await this.neiBondContract.methods
        .depositLP(info, sig)
        // .depositLP(account, tokenAddress, amount, tid)
        .send({
          from: account
        });
      return res;
    } catch (err) {
      console.log(err);
      return err
    }
  }

  // 签名存款债券，需要后端传入代币->NEI的价格
  // 非常规稳定币
  // info 数据信息
  // sig 签名信息

  // struct DepositInfo {
  //   address depositContract; // 债券合约地址
  //   address principal;	//代币地址
  //   address depositor;	// 抵押受益地址
  //   uint256 amount;	// 抵押金额
  //   uint256 maxPrice;	// 最大价格
  //   uint256 makeTime;	// 制作时间
  //   uint256 tokenToNEIPrice;// 1稳定币用NEI衡量的价格，需要放大10**18次方
  //   uint256 salt;// 顺序盐
  // }

  async depositFor(info, sig) {
    const account = await this.getCurrentAccount();
    try {
      return await this.neiBondContract.methods.depositFor(info, sig).send({
        from: account
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // 查询计算存款人可申请的NEI金额
  async pendingPayoutFor(tokenAddress, tid) {
    const account = await this.getCurrentAccount();
    try {
      // const principal = tokenAddress; // 代币地址

      const depositor = account; // 受益地址
      const pendingPayout = await this.neiBondContract.methods
        .pendingPayoutFor(depositor, tokenAddress, tid)
        .call();
      return bNToN(pendingPayout, networkConfig.nedToken.decimals);
    } catch (err) {
      console.log(err);
      return;
    }
  }

  //  赎回
  async redeem(tokenAddress, tid) {
    const account = await this.getCurrentAccount();
    try {
      const principal = tokenAddress; // 代币地址
      const recipient = account; // 受益地址
      const stake = false; // 是否再次抵押
      return await this.neiBondContract.methods.redeem(recipient, tokenAddress, tid, false).send({
        from: account
      });
    } catch (err) {
      console.log(err);
      return;
    }
  }

  // 查询与maxPrice进行比较的计算值
  async getNativePricePrice(tokenAddress) {
    try {
      const principal = tokenAddress; // 代币地址
      const maxPrice = await this.neiBondContract.methods
        .getNativePricePrice(principal)
        .call();
      // return bNToN(maxPrice, networkConfig.nedToken.decimals);
      return maxPrice;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  // 计算当前债券溢价
  // 查询与maxPrice进行比较的计算值
  async getBondPrice(tokenAddress) {
    try {
      const principal = tokenAddress; // 代币地址
      const maxPrice = await this.neiBondContract.methods.bondPrice().call();
      // return bNToN(maxPrice, networkConfig.nedToken.decimals);
      return maxPrice;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  // 根据代币地址，查询基础信息
  // 获取当前已购买的NEI值，即当前总负债
  async getPrincipleInfo(tokenAddress) {
    try {
      const principal = tokenAddress; // 代币地址
      const principleInfo = await this.neiBondContract.methods
        .principleInfo(principal)
        .call();
      return {
        isLiquidityBond: principleInfo.isLiquidityBond, // 是否为流动性代币
        bondCalculator: principleInfo.bondCalculator, // 债券计算器很讨厌
        totalDebt: bNToN(
          principleInfo.totalDebt,
          networkConfig.nedToken.decimals
        ), // 当前总负债

        lastDecay: principleInfo.lastDecay // 上次更新区块
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }
  // 查询使用deposit接口时，得到的预期NEI数量
  // 购买预期可获得NEI数量
  async getExceptNEIAmount(tokenAddress, amount, isDec = true, tid, bindTokenToMarq) {
    // tokenAddress = '0x9a0aef1fe758de03f9b5efef5fe9a5c19992425b'
    try {
      // const principal = tokenAddress; // 代币地址
      if (isDec) {
        const decimals = await this.getTokenDecimals(tokenAddress);
        amount = nToBN(amount, decimals); // 代币数量
      }
      const result = await this.neiBondContract.methods
        .payoutFor(tokenAddress, amount, tid, bindTokenToMarq)
        .call();


      const exceptNEIAmount =
        +result.payout_ < +result.value_ ? result.payout_ : result.value_;

      return bNToN(exceptNEIAmount, networkConfig.nedToken.decimals);
    } catch (err) {
      console.log(err);
      return;
    }
  }

}


export default NeiBondService;
