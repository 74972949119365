import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import "./index.scss";
import { useTranslation } from "react-i18next";
import CoinImg from '../../assets/images/act_coin.png'
import { sendAnalytic } from "../../utils/analytic";

function AppIdo(props) {
  const below960 = useMedia("(max-width: 960px)");
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const [la, setLa] = useState(localStorage.getItem('i18nextLng'))

  useEffect(() => {
    setLa(localStorage.getItem('i18nextLng'))
  }, [localStorage.getItem('i18nextLng')])

  return (
    <div className="app_ido_box">
      <div
        className={["app_ido", 'app_ido2', la == 'en' || la == 'EN' ? "app_ido2_en" : ''].join(' ')}
        onClick={(e) => {
          sendAnalytic("promotion_enter", { account: "", action: 'Top Banner' })
          history.push('/ido');
        }}>

        <div className="text1">
          {t('ido_text1')}
        </div>
        <div className="text2">
          {t('ido_text2')}

          {la == 'zh' || la == 'ZH' ? <span>{t('ido_text3')}</span> : ''}
        </div>
        <div className="text2">

          {
            la == 'en' || la == 'EN' ?
              <>{t('ido_text3')}  <span>{t('ido_text4')}</span></> :
              t('ido_text4')
          }

        </div>
        <div className="text1 text3">
          {t('ido_text5')}
        </div>
      </div>
    </div>
  );
}

export default AppIdo;
