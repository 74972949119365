// BSC 测试
export default {
  dappUrl: "http://47.241.66.95/marquee-dapp-dev/",
  siteUrl: "https://marquee.fi/",
  baseUrl: "https://ism-client-bsc-dev.nodedao.io",
  documentUrl: "https://docs.marquee.fi/",
  documentUrlZH: "https://docs.marquee.fi/",
  inviteUrl: "http://test.marquee.fi/coinInsurancelist/",

  // 安全险
  ethRpc: "https://rpc.test.deriw.com/",
  posRootERC20: "0x248239C646C0Fa8EaCcD0E321cd6EE7EF41dD9EF", // USDT
  CSInsurance: "0x405Be2179a8FE1fc0F5aa0b24101FEB40E2333EC",
  RewardPool: "0x5Ccf79fa1507425B7bD39Afe8713e1efb404a9eA",
  VoteDAO: "0xEab099531D63c9A9a29DD24A3c729E63001298Ad",
  IPSTToken: '0x2619943C2726ea6eF0251cC47858bbDcfeB1BCA6', // CPST
  pendant: "0x48C26ac2Fd2557b104e6F55137d52b813aB41ff2",


  networkId: 35318034165, // 测试
  blockExplorerUrls: "https://explorer.test.deriw.com/", // 网站地址
  wsUrl: "wss://ism-client-bsc-dev.nodedao.io",
  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币地址
  mainChainSymbol: "tDeriw", // 主链币名称
  blockTime: 0.3, // 出一个块的时间(秒)

  ismAddr: "0x9a398ee3E96DC0Ae06EbC0005D70E547E01382bF",
  
  usdtToken: {
    address: "0x248239C646C0Fa8EaCcD0E321cd6EE7EF41dD9EF",
    symbol: "USDT",
    decimals: 18
  },
  v2LpToken: {
    address: "0x4080566Ec6697a8B91f9176d54cC08db77583a93",
    symbol: "v2LP",
    decimals: 18
  },

  // 币价险
  lpToken: {
    address: "0x4080566Ec6697a8B91f9176d54cC08db77583a93",
    symbol: "IPST",
    decimals: 18
  },
  erc721ContractAddr: "0xd3F2Ba7c0C4A69f268E2b2bF859d3f84e46d1de6",
  rewardPoolContractAddr: "0x34Cef35810A618015d751A066E1699A8b654214f",
  insuranceContractAddr: "0xB40FEC9558bBf029B6520CbA2cF593B3CdC1b966",
  InsurePay: "0x0E40fc6d867d4Fd70F67b0b37957B22a7066dBc0",
  Integral: "0x9a45E9a27aD3c3B0cd2bCc40Ee55DcE202A8F733",
  marqueeInscription: "0x7546232b608EbED957A3CBF5696bbFD5549c9e5f",
  masterChefContractAddr: "0xF8EF514917a78b0A7E5E043983FFcEbe3BFcAa7d", // sunset
  ismLockPoolContractAddr: "0x0BB8584F5Bc236533a6A4790802104222D26B9F4", // sunset

  // 国库 债卷合约
  neiBondAddr: "0x38FF6F2F51bd317613313404262c1D4e773364f8",
  neiVaultAddr: "0x3CbA22Cc88C728F36de31f1aD6784799FA0b725e", // 国库

  // 年金保险
  Auth: "0xB5FAef52F1799a017641496cF9aFc54705A54535",
  Factory: "0x193DAA5bBE07cdEb5506AFd5C4917Df7DD7DAacc",
  Algorithm: "0x255Ea4172537F9b9357ed5f47Aab010550c2c78f",
  AnnuityData: "0xF2bdD80DBf0F3329CCD5b76CBddFF4AF31BAdF13",
  RouterV1: "0xeBc7F3519bdc66263C509D89103A61503D7Dcc55",
  ErrorContract: "0xA03C0Cf39A866248B2DF5eeA92ADdfb5A6C3dC78",
  
  // 交易
  Reward: "0x61b8b9C65bF8C5D8c958BBC338a9F03e26785463",

  nedToken: {
    address: "0xB2d93eFB70994F3B6682d508E1C8Ad66dCa65193",
    symbol: "NED",
    decimals: 9
  },
  sNedToken: {
    address: "0xe559A137caF50b5407c54c8f451eFb654048c170",
    symbol: "sNED",
    decimals: 9
  },
  gNedToken: {
    address: "0xc5DD1E7e8b60a2Bd2a83C6E5295F35A7e388E25B",
    symbol: "gNED",
    decimals: 18
  },
  usdtToken: {
    address: "0x248239C646C0Fa8EaCcD0E321cd6EE7EF41dD9EF",
    symbol: "USDT",
    decimals: 18
  },
  inviteMerkleDistributor: "0xfc28eaB357F43792DA495dA3F92988E36B516D7C",
  // 挖矿
  Bonus: "0xbE07d61148D3a1E6291949c67D8F964A19FcFb46",
  // IDO 平台
  idozenix: "0x1b28AEdacfB3Abf79db8a1b8bd2A85E2f60aD79E",
  ido: {
    matic: {
      rpc: 'https://polygon-rpc.com/',
      chainId: 137,
      address: "0x5e2aFf47347d992E8F9Ad07A3cdad73C77ebB982"
    },
    bsc: {
      rpc: 'https://bsc-dataseed.binance.org/',
      chainId: 56,
      address: "0xDd0Cb33e6b0a904D522084d98a2e524003D499F4"
    },
    arb: {
      rpc: 'https://arb1.arbitrum.io/rpc',
      chainId: 42161,
      address: "0x890FC597E701eaDA882DDe364b9e7D134E24c555"
    }
  },

  oldMarq: "0xc76F16E450aFDA7f24d2546416F5FBEE5d37bAFb",
  newMarq: "0xc76F16E450aFDA7f24d2546416F5FBEE5d37bAFb",
  Trading: "0xbb60d418AB2C925C6c8A09a40812670CdD3bd131",
  MarqSwap: "0x33E2D81A7d2AD4aB41B5E422D548e5c40Ad20dB3",

  "twitterUrl": "https://twitter.com/Marquee_fi",
  "mediumUrl": "https://medium.com/@MarqueeOfficial",
  "discordUrl": "https://discord.com/invite/cp7akF4bg9",
  "telegramUrl": "https://t.me/MarqueeGroup",
  "gtm": "G-K9H76Q07BR",
};


// ARB 测试
// export default {
//   dappUrl: "http://47.241.66.95/marquee-dapp-dev-arb/",
//   siteUrl: "https://marquee.fi/",
//   // baseUrl: "http://161.117.249.180:9005",
//   // baseUrl: "https://ism-client-bsc-magnify.nodedao.io",
//   baseUrl: "https://ism-client-bsc-dev.nodedao.io",
//   documentUrl: "https://docs.marquee.fi/",
//   documentUrlZH: "https://docs.marquee.fi/",
//   inviteUrl: "http://47.241.66.95/marquee-invite-dev-arb/",

//   // 安全险
//   ethRpc: "https://bsc-testnet.public.blastapi.io",
//   posRootERC20: "0x20A48DA1f0fD7183F26D394aEC89FDC790DFeE42", // USDT
//   CSInsurance: "0x3a73f25Aa7E758EF0cd77e9FE29b9Bb4c233Db29",
//   RewardPool: "0x8fC85e4E5cb24BAc19565Faf3A58fDb985baC4f6",
//   VoteDAO: "0xf70F1970850DDE3AE30C2604f233a0A597A6c825",
//   IPSTToken: '0xC22EC9eE0e0a7916FC743E9a683f2C34d9a82ee6', // CPST
//   NEDToken: '0xD021E277657665e9f6DBb82e553FEB95F446621C',
//   GNEDToken: '0xE40050d397371C3Cb8f16b4BF55B2E3dEdd4Ba6a',
//   pendant: "0x3A2e8EdBf478C99f34217B0291DF2B475288a5FF",


//   networkId: 421613, // 测试
//   blockExplorerUrls: "https://testnet.bscscan.com", // 网站地址
//   wsUrl: "wss://ism-client-bsc-magnify.nodedao.io",
//   mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币地址
//   mainChainSymbol: "ETH", // 主链币名称
//   blockTime: 15, // BSC出一个块的时间(秒)

//   ismAddr: "0x14A043259983a6a9a65b4F536902a8359E749607",

//   usdtToken: {
//     address: "0x20A48DA1f0fD7183F26D394aEC89FDC790DFeE42",
//     symbol: "USDT",
//     decimals: 6
//   },
//   v2LpToken: {
//     address: "0x20A48DA1f0fD7183F26D394aEC89FDC790DFeE42",
//     symbol: "v2LP",
//     decimals: 6
//   },

//   // 币价险
//   lpToken: {
//     address: "0x480F97c9f52305ADfB091e99E8c52476902e57D9",
//     symbol: "IPST",
//     decimals: 6
//   },
//   erc721ContractAddr: "0xdCe24d312b5935b471CC61887dFb2232A818286d",
//   rewardPoolContractAddr: "0xaBcBf61Bd3f6D908F9A51A3fafA183eDA10Be4e1",
//   insuranceContractAddr: "0xE68b063f77E0bf3b50382dF49B98Da643e06Be6a",
//   InsurePay: "0x3B06379e4a8960476B473A690f9e24c1c7514Da1",
//   masterChefContractAddr: "0xF8EF514917a78b0A7E5E043983FFcEbe3BFcAa7d", // sunset
//   ismLockPoolContractAddr: "0x0BB8584F5Bc236533a6A4790802104222D26B9F4", // sunset

//   // 国库 债卷合约
//   neiBondAddr: "0xF243037563b2a639d0436C41AAe87107C83F62f1",
//   neiVaultAddr: "0xe9AdD60F8F52B1f1Dd2b3806c03F1Cd31647df94", // 国库

//   // 年金保险
//   Auth: "0x8350c5072553Ec9AB4c703dFB33D1856A3c9a4c7",
//   Factory: "0xD300A94945Ba514725ca2fd7C18E5c11d32D3500",
//   Algorithm: "0xb5e1c1f8d2AD32D5d04192f2D80BE274e1327992",
//   AnnuityData: "0x2fFB1DC5F59c0C01b07156e508D4366518E65e4c",
//   RouterV1: "0xF0eD60a8a3D06F871999ec96dDd3E1ACdbEC44D2",
//   ErrorContract: "0xeb183099C879b7c632Df9E27e2Bb811dD384A5f7",

//   nedToken: {
//     address: "0xD021E277657665e9f6DBb82e553FEB95F446621C",
//     symbol: "NED",
//     decimals: 9
//   },
//   sNedToken: {
//     address: "0x54Cd6AF7Ddf067ca305055AE76f25Dd9323dbc22",
//     symbol: "sNED",
//     decimals: 9
//   },
//   gNedToken: {
//     address: "0xE40050d397371C3Cb8f16b4BF55B2E3dEdd4Ba6a",
//     symbol: "gNED",
//     decimals: 18
//   },
//   usdtToken: {
//     address: "0x20A48DA1f0fD7183F26D394aEC89FDC790DFeE42",
//     symbol: "USDT",
//     decimals: 6
//   },
//   inviteMerkleDistributor: "0xf54de1deebe8242dcdb64da6ab5616aa4990985f",

//   oldMarq: "0xa40F3BF4A45881153BAD36c77f07C6Cf0c0f42e7",
//   newMarq: "0xAFfC5a619dCaB1cf1cBA4D710F2d213E073347B2",
//   Trading: "0x96A56CF0fE06c9C3dd6d1bc1eE752A4Ce17dF616",
//   MarqSwap: "0xe1E89FbF0a07e10F0E4321622b89492e08EB5e42",

//   "twitterUrl": "https://twitter.com/Marquee_fi",
//   "mediumUrl": "https://medium.com/@MarqueeOfficial",
//   "discordUrl": "https://discord.com/invite/cp7akF4bg9",
//   "telegramUrl": "https://t.me/MarqueeGroup",
//   "gtm": "G-K9H76Q07BR",
// };
