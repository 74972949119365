import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import rewardPoolABI from "./abi/RewardPoolABI";

import { nToBN, bNToN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class RewardPoolService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      // 币价险
      this.rewardPoolContract = new this.web3.eth.Contract(
        rewardPoolABI,
        networkConfig.rewardPoolContractAddr
      );


      // 合约险
      this.cRewardPoolContract = new this.web3.eth.Contract(
        rewardPoolABI,
        networkConfig.RewardPool
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  // 查询资金池信息
  async getPoolInfo() {
    try {
      const poolInfo = await this.rewardPoolContract.methods.poolInfo().call();
      return {
        totalAmount: bNToN(
          poolInfo.totalAmount,
          networkConfig.usdtToken.decimals
        ), // 资金总额
        earnAmount: bNToN(
          poolInfo.earnAmount,
          networkConfig.usdtToken.decimals
        ), // 累计赚取金额
        loseAmount: bNToN(poolInfo.loseAmount, networkConfig.usdtToken.decimals) // 累计赔付金额
        // maxTakeAmount: bNToN(
        //   poolInfo.maxTakeAmount,
        //   networkConfig.usdtToken.decimals
        // ) // 当前资金池占用金额
      };
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  // 资金池放大倍数
  async getMagnifyRatio() {
    try {
      const magnifyRate = await this.rewardPoolContract.methods.TEN().call();
      return {
        magnifyRate: magnifyRate
      };
    } catch (e) {
      console.log(e);
      return {
        magnifyRate: 0
      };
    }
  }

  // 查询承保人ID数量
  async getInsurerNum() {
    try {
      const insuerNum = await this.rewardPoolContract.methods
        .insurerID()
        .call();
      return insuerNum;
    } catch (e) {
      console.log(e);
      return 0;
    }
  }

  // 查询指定地址lp数量
  async getLpTokenBalance() {
    const account = await this.getCurrentAccount();
    try {
      const lpTokenBalance = await this.rewardPoolContract.methods
        .getBalanceOf(account)
        .call();
      return bNToN(lpTokenBalance, networkConfig.lpToken.decimals);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询lp总量 合约币价险
  async getTotalLpTokenAmount() {
    try {
      const totalLpTokenAmount = await this.rewardPoolContract.methods
        .totalSupplyAmount()
        .call();
      return bNToN(totalLpTokenAmount, networkConfig.lpToken.decimals);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询lp总量 合约险
  async getCTotalLpTokenAmount() {
    try {
      const totalLpTokenAmount = await this.cRewardPoolContract.methods
        .totalSupplyAmount()
        .call();
      return bNToN(totalLpTokenAmount, networkConfig.lpToken.decimals);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 当前可以使用的最大lp 通过总负债查询（保单的总价值）
  async getMaxLpAmount(totalLiab) {
    try {
      const maxLpAmount = await this.rewardPoolContract.methods
        .getMaxLPAmount(totalLiab)
        .call();
      return bNToN(maxLpAmount, networkConfig.lpToken.decimals);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询解锁时间
  async getUnlockTime() {
    const account = await this.getCurrentAccount();
    try {
      const unlockTime = await this.rewardPoolContract.methods
        .insurerUnlockTime(account)
        .call();
      return unlockTime;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询当前时间戳
  async getCurrTime() {
    try {
      const currTime = await this.rewardPoolContract.methods
        .getCurrTime()
        .call();
      return currTime;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 锁定时间
  async getUnlockCountTime() {
    // 解锁时间  >  当前时间 ==> 锁定中
    // 当前时间  >  解锁时间 ==> 已解锁
    const unlockTime = await this.getUnlockTime();
    const currTime = await this.getCurrTime();
    const unlockCountTime = unlockTime - currTime;
    return unlockCountTime;
  }

  // 根据hash，注资或者赎回资金信息，签名,效验其是否有效
  async validateOrder(hash, assets, sig) {
    const account = await this.getCurrentAccount();

    try {
      const result = await this.rewardPoolContract.methods
        .validateOrder(hash, assets, sig)
        .call({ from: account });
      return result;
    } catch (e) {
      console.log(e.message);

      let code = 100;
      if (e.message.indexOf("execution reverted: address err") > -1) {
        code = 1;
      } else if (e.message.indexOf("execution reverted: amount err") > -1) {
        code = 2;
      } else if (e.message.indexOf("execution reverted: time err") > -1) {
        code = 3;
      } else if (
        e.message.indexOf("execution reverted: the hash has tx") > -1
      ) {
        code = 4;
      } else if (
        e.message.indexOf("execution reverted: not sig address") > -1
      ) {
        code = 5;
      } else if (e.message.indexOf("execution reverted: owner err") > -1) {
        code = 6;
      } else if (e.message.indexOf("execution reverted: Insolvent err") > -1) {
        code = 7;
      }

      return {
        code
      };

      // 1. address err：资金池地址、或者注资币地址、或者IPST地址、或者ism2地址错误（合约地址错误）
      // 2. amount err：注资金额小于等于0（注资金额错误）
      // 3. time err：注资单制作时间大于当前时间（即未未来时间）， 或者注资单制作时间+延迟时间小于等于当前时间（即超出上链最大延迟时间）（注资时间错误）
      // 4. the hash has tx：这笔签名资金已经注入过了（注资签名错误）
      // 5. not sig address：签名地址不是当前合约后台管理使用的签名地址（签名地址错误）
      // 6. owner err：当前注资调用地址不是注资单中写明的地址（注资地址错误）
      // 7. Insolvent err：资不抵债，不能赎回和注资（资不抵债不能注资）

      // 1. address err：资金池地址、或者注资币地址、或者IPST地址、或者ism2地址错误
      // 2. amount err：注资金额小于等于0
      // 3. time err：注资单制作时间大于当前时间（即未未来时间）， 或者注资单制作时间+延迟时间小于等于当前时间（即超出上链最大延迟时间）
      // 4. the hash has tx：这笔签名资金已经注入过了
      // 5. not sig address：签名地址不是当前合约后台管理使用的签名地址
      // 6. owner err：当前注资调用地址不是注资单中写明的地址
      // 7. totalLiabilities err：保单总额大于资金池金额
      // 8. total lp not enough：用户持有的IPST数量加上抵押在矿池挖矿的IPST小于填入的数量
      // 9. dfs err：赔付的金额大于空闲资金
      // 10. lp not unlockTime： 抵押挖矿的IPST没有到解锁的时间
      // 11. owner lp not enough：用户的IPST数量不够
    }
  }

  // 资金池取回操作的参数验证
  async validateClaim(assets) {
    const account = await this.getCurrentAccount();

    try {
      const isClaim = true;
      const result = await this.rewardPoolContract.methods
        .checkClaim(assets, isClaim)
        .call({ from: account });
      return result;
    } catch (e) {
      console.log(e.message);

      let code = 100;
      if (e.message.indexOf("execution reverted: totalLiabilities err") > -1) {
        code = 1;
      } else if (e.message.indexOf("execution reverted: lp amount err") > -1) {
        code = 2;
      } else if (
        e.message.indexOf("execution reverted: unLock not enough") > -1
      ) {
        code = 3;
      } else if (
        e.message.indexOf("execution reverted: owner lp not enough") > -1
      ) {
        code = 4;
      } else if (e.message.indexOf("execution reverted: dfs err") > -1) {
        code = 5;
      }

      // 1. totalLiabilities err: 保单总额大于资金池金额（资金池余额不足）
      // 2. lp amount err: 用户填入的ipst的数量大于合约累计增发的ipst，或者ipst合约当前累计增发的ipst小于等于0（IPST数量错误）
      // 3. unLock not enough：抵押在矿池挖矿的ipst解锁数量不足（IPST数量不足）
      // 4. owner lp not enough：当不取回抵押在矿池中的ipst时，用户自身持有的ipst数量小于想要赎回的数量（IPST余额不足）
      // 5. dfs err：用户赎回资产后导致资金池出现资不抵债的情况，不允许赎回（资金池资不抵债）

      return {
        code
      };
    }
  }

  // 存入USDT
  async depositUSDT(assets, sig) {
    const account = await this.getCurrentAccount();

    try {
      // const isMintFor = false; // 前期默认false
      return await this.rewardPoolContract.methods
        .addAssets(assets, sig/*, isMintFor*/)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  // 取回lp
  async withdrawUSDT(assets, sig, tokenIds) {
    const account = await this.getCurrentAccount();

    try {
      // const tokenIds = []; // 需要销毁的tokenID数组(以后扩展用)
      const isClaim = false;
      return await this.rewardPoolContract.methods
        .claimAssets(assets, sig)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  // 查询资不抵债的比率
  async outRate() {
    try {
      const result = await this.rewardPoolContract.methods.outRate().call();
      return result / 10000;
    } catch (e) {
      console.log(e);
      return;
    }
  }
}

export default RewardPoolService;
