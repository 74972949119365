import { ANTHOR, ISEXIT } from "./action";

const globalState = {
  anthor: '',
  isExit: false
};

const global = (state = globalState, action) => {
  if (action.type === ANTHOR.type) {
    return action.payload;
  } else if (action.type === ISEXIT.type) {
    state.isExit = action.payload
    return state
  } else {
    return state;
  }
};

export default global;
