import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import PendantABI from "./abi/Pendant.json";

import { bNToN, nToBN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class PendantService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.pendantContract = new this.web3.eth.Contract(
        PendantABI,
        networkConfig.pendant
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  // async claimUserOrder(claim, sig) {
  //   const account = await this.getCurrentAccount();
  //   try {
  //     return await this.pendantContract.methods
  //       .claimUserOrder(claim, sig)
  //       .send({ from: account });
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }
  // }

  // 查询申请理赔的百分比
  async getMixRate() {
    try {
      const amount = await this.pendantContract.methods
        .mixRate()
        .call();
      return amount / 10000;
      // return bNToN(amount, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }
  // 申诉
  async getAgainRate() {
    try {
      const res = await this.pendantContract.methods
        .againRate()
        .call();
      return res / 10000
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  // 获取最新的nonce, 返回0则表示没有
  async getOrderApplyNonce(orderId) {
    try {
      const amount = await this.pendantContract.methods
        .orderApplyNonce(orderId)
        .call();
      // return amount / 10000;
      return amount
    } catch (e) {
      console.log(e);
      return;
    }
  }
  //查询是否已经申诉
  async getResult(orderId) {
    try {
      const nonce = await this.getOrderApplyNonce(orderId)
      const amount = await this.pendantContract.methods
        .result(orderId, nonce)
        .call();
      // return amount / 10000;
      return amount
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 基础值
  async getBaseRate() {
    try {
      // const baseRate = await this.pendantContract.methods
      //   .baseRate()
      //   .call();
      // return baseRate
      return 10000
    } catch (e) {
      console.log(e);
      return;
    }
  }
}

export default PendantService;
