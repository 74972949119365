// 线上环境配置
export default {
  dappUrl: "https://test.marquee.fi/",
  siteUrl: "https://marquee.fi/",
  baseUrl: "https://testapi.marquee.fi",
  // baseUrl: "https://api.marquee.fi",
  documentUrl: "https://docs.marquee.fi/",
  documentUrlZH: "https://docs.marquee.fi/",
  inviteUrl: "https://invite.marquee.fi",

  // 安全险
  ethRpc: "https://arbitrum.llamarpc.com",
  posRootERC20: "0xf9c3f9E877E0F9A2c727f5f7140ee7332fFe45e3", // USDT
  CSInsurance: "0xAe44DE6a4499032676708A8Fb92874AB65eb5f38",
  RewardPool: "0x22Af4b4e0728cE5C620461BD7eA376cA5a0a0e6D",
  VoteDAO: "0xcdc8DB5759C6a50424c9D42bb657545c4CFC4E4f",
  IPSTToken: '0x4627eFe5282760088Bc25e2B6cEB67AA3cce6dB4', // CPST

  networkId: 42161, // bsc生产
  blockExplorerUrls: "https://arbiscan.io/", // 网站地址
  wsUrl: "wss://testapi.marquee.fi",
  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币地址
  mainChainSymbol: "Arbitrum", // 主链币名称
  blockTime: 3, // BSC出一个块的时间(秒)

  ismAddr: "0xA6e7aa55e928fccDd0afF23a82D5b3bF24551A5a",

  usdtToken: {
    address: "0xf9c3f9E877E0F9A2c727f5f7140ee7332fFe45e3",
    symbol: "USDT",
    decimals: 6
  },
  v2LpToken: {
    address: "0xc580C33336d8870b59E178dc488B4E7B3f2976d6",
    symbol: "v2LP",
    decimals: 6
  },

  // 币价险
  lpToken: {
    address: "0x4627eFe5282760088Bc25e2B6cEB67AA3cce6dB4",
    symbol: "IPST",
    decimals: 6
  },
  erc721ContractAddr: "0x8319440B9FD6E192E3A0FAfFE9d1AAA30b2BBCF7",
  rewardPoolContractAddr: "0xEd21824A0adAcbe14bca4F638203bA7d2692eD9A",
  insuranceContractAddr: "0xA6e7aa55e928fccDd0afF23a82D5b3bF24551A5a",
  masterChefContractAddr: "0xF8EF514917a78b0A7E5E043983FFcEbe3BFcAa7d", // sunset
  ismLockPoolContractAddr: "0x0BB8584F5Bc236533a6A4790802104222D26B9F4", // sunset

  // 国库 债卷合约
  neiBondAddr: "0x3Ea799F80199F0ABDA236239a96358016881fD67",//"0xb492A99B818Fe56A5B228c2f91C38535d056EF7e", // 债券合约
  neiVaultAddr: "0xdBE75CB0f179169638Db1046D81eED9F6B68d5d4", // 国库

  nedToken: {
    address: "0x36135829766eA4ccf87D0655704853a53DEabBA7",
    symbol: "NED",
    decimals: 9
  },
  sNedToken: {
    address: "0x4DD3Ec047419BC7c06fA9f0b79e5c1904ba451F8",
    symbol: "sNED",
    decimals: 9
  },
  gNedToken: {
    address: "0xd8A8E988032C2Fc19100080F8Bfd8A23Fc98907F",
    symbol: "gNED",
    decimals: 18
  },
  usdtToken: {
    address: "0xf9c3f9E877E0F9A2c727f5f7140ee7332fFe45e3",
    symbol: "USDT",
    decimals: 6
  },

  inviteMerkleDistributor: "0x9Dd0bc94cbce770B3039CbF417451B5Bb5f79E40",

  "twitterUrl": "https://twitter.com/Marquee_fi",
  "mediumUrl": "https://medium.com/@MarqueeOfficial",
  "discordUrl": "https://discord.com/invite/cp7akF4bg9",
  "telegramUrl": "https://t.me/MarqueeGroup",
  "gtm": "G-K9H76Q07BR",
};