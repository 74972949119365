import { HashRouter, Route, Switch, Redirect, BrowserRouter, useLocation } from "react-router-dom";
import "./App.scss"
// import createHistory from "history/createBrowserHistory";
import { createBrowserHistory } from "history";
import Nav from "../src/components/Nav";
import AppAirDrop from "../src/components/AppAirDrop";
import AppAnnuity from "../src/components/AppAnnuity";
import AppIdo from "../src/components/AppIdo";
import SelectLang from "../src/components/SelectLang";
import USDTImg from "../src/assets/images/usdt.png";
import TBNBImg from "../src/assets/images/tbnb.png";
import marqueeImg from "../src/assets/images/marq_token.png";
import { useTranslation } from "react-i18next";
import { getTestUSDT, getTestMarq } from '../src/api'
import { showTip, closeTip } from "../src/components/common/CMessageTip";
import { ContractServe } from "../src/contracts";
import TabNatural from "../src/components/TabNatural";
import TabCoin from "../src/components/TabCoin";
import React, { Suspense, useEffect, useState } from "react";
import useDevice from "./hooks/useDevice";
import config from "./config";
import loadable from "./utils/loadable";
import { useSelector, useDispatch } from "react-redux";
import { SWITCH_NETWORK } from "./store/network/action";
import ReactGA from "react-ga4";



const AirDrop = loadable(() => import("./pages/AirDrop"));
const CryptoActivity = loadable(() => import("./pages/CryptoActivity"));
const Ido = loadable(() => import("./pages/Ido"));
const Home = loadable(() => import("./pages/home"));
const Detail = loadable(() => import("./pages/detail"));
const Pool = loadable(() => import("./pages/pool"));
const Policy = loadable(() => import("./pages/policy"));

const Earn = loadable(() => import("./pages/Earn"));

const Claim = loadable(() => import("./pages/claim"));
const ClaimContract = loadable(() => import("./pages/claimContract"));
const ClaimAssessor = loadable(() => import("./pages/ClaimAssessor"));
const VoteHistory = loadable(() => import("./pages/ClaimAssessor/VoteHistory"));
const ClaimAssessorGovernment = loadable(() => import("./pages/ClaimAssessorGovernment"));
const ClaimVote = loadable(() => import("./pages/ClaimVote"));

const Mine = loadable(() => import("./pages/Mine"));
const CoinPool = loadable(() => import("./pages/CoinPool"));
const Treasury = loadable(() => import("./pages/Treasury"));
const TreasuryList = loadable(() => import("./pages/Treasury/List"));
const BondBuy = loadable(() => import("./pages/Treasury/Buy"));
const MyBond = loadable(() => import("./pages/Treasury/MyBonds"));
const BondExtract = loadable(() => import("./pages/Treasury/Extract"));

const Insurance = loadable(() => import("./pages/Insurance"));
const InsuranceList = loadable(() => import("./pages/InsuranceList"));
const NodeDaoHome = loadable(() => import("./pages/NodeDaoHome"));
const NonePage = loadable(() => import("./pages/None"));

const MoreDocument = loadable(() => import("./pages/More/Document"));
const MoreRecommend = loadable(() => import("./pages/More/Recommend"));
const Annuity = loadable(() => import("./pages/Annuity"));
const AnnuityIns = loadable(() => import("./pages/AnnuityIns"));
const AnnuityInsDetail = loadable(() => import("./pages/AnnuityInsDetail"));
const Mining = loadable(() => import("./pages/Mining"));

const SwitchNetworkModal = React.lazy(() =>
  import("./components/SwitchNetworkModal")
);

const ReceiveTBNB = () => {

  const { t, i18n } = useTranslation();
  return <div
    className={["select_lang", "receive_token", "mr-8"].join(" ")}
    onClick={() => {
      if (i18n.language.toUpperCase() === "ZH") {
        window.open(`${config.dappUrl}BSCTestnetGas费用领取流程.pdf`)
      } else {
        window.open(`${config.dappUrl}BSCTestnetGasfeeCollectionProcess.pdf`)
      }
    }}
  >
    <div className="lang_text">
      <img className="lang_img" src={TBNBImg} />
    </div>
    {t('receive_token', { token: "Gas Fee" })}
  </div>
}

const ReceiveTUSDT = () => {

  const { t } = useTranslation();
  const [account, setAccount] = useState();
  useEffect(async () => {
    const account1 = await ContractServe.getCurrentAccount();
    setAccount(account1);
  }, [account]);

  return <div
    className={["select_lang", "receive_token", "mr-8"].join(" ")}
    onClick={() => {
      if (account) {
        getTestUSDT({ account: account }).then((res) => {
          if (res.indexOf("0x") > -1) {
            showTip('receiveTUSDT', 'success', t('receive_token_success', { token: "TUSDT" }))
          } else {
            showTip('receiveTUSDT', 'error', t('receive_token_fail_received', { token: "TUSDT" }))
          }
        }).catch((err) => {
          showTip('receiveTUSDT', 'error', t('receive_token_fail', { token: "TUSDT" }))
        })
      } else {
        showTip('receiveTUSDT', 'error', t('no_address_detected'))
      }
    }}
  >
    <div className="lang_text">
      <img className="lang_img" src={USDTImg} />
    </div>
    {t('receive_token', { token: "TUSDT" })}
  </div>
}

const ReceiveTMarq = () => {

  const { t, i18n } = useTranslation();
  const [account, setAccount] = useState();
  useEffect(async () => {
    const account1 = await ContractServe.getCurrentAccount();
    setAccount(account1);
  }, [account]);

  return <div
    className={["select_lang", "receive_token", "mr-8"].join(" ")}
    onClick={() => {
      if (account) {
        getTestMarq({ account: account }).then((res) => {
          console.log("res", res)
          if (typeof res == "string") {
            showTip('receiveMarq', 'success', t('receive_token_success', { token: "MARQ" }))
          } else {
            if (res.code == 100437) {
              let timing = res.reference.split(" left until next allowance")[0]
              if (i18n.language.toUpperCase() == 'ZH') {
                timing = timing.replace("h", t("text113"))
                timing = timing.replace("m", t("text114"))
                timing = timing.replace("s", t("text115"))
              }
              showTip('receiveMarq', 'error', t('receive_token_time_left', { time: timing }))
            } else {
              showTip('receiveMarq', 'error', t('receive_token_fail', { token: "MARQ" }))
            }
          }
        }).catch((err) => {
          showTip('receiveMarq', 'error', t('receive_token_fail', { token: "MARQ" }))
        })
      } else {
        showTip('receiveMarq', 'error', t('no_address_detected'))
      }
    }}
  >
    <div className="lang_text">
      <img className="lang_img" src={marqueeImg} />
    </div>
    {t('receive_token', { token: "MARQ" })}
  </div>
}


function GA() {
  usePageViews();
  return <></>
}

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
}


const App = () => {
  const { isPc, isMobile } = useDevice();
  const dispatch = useDispatch();
  const [isShowAirDrop, setShowAirDrop] = useState(false)
  const Layout = props => (
    <div className="App_box">

      {
        isShowAirDrop && isPc ?
          // <AppAirDrop setShowAirDrop={setShowAirDrop} /> : ''
          <AppIdo setShowAirDrop={setShowAirDrop} /> : ''
      }

      <div className="App">
        <div className="left"
          style={{
            // transform: `translateY(${isShowAirDrop ? isMobile ? 60 : 50 : 0}px)`
          }}
        >
          <Nav isShowAirDrop={isShowAirDrop} />
        </div>
        <div className="main"
          // style={{ paddingTop: isShowAirDrop ? 70 : 0 }}

          style={{
            // transform: `translateY(${isShowAirDrop ? 50 : 0}px)`,
            // paddingTop: isPc ? 560 : 0
          }}
        >
          <SelectLang />
          {/* {config.mainChainSymbol == "BSC Testnet" && <ReceiveTMarq />}
          {config.mainChainSymbol == "BSC Testnet" && <ReceiveTUSDT />}
          {config.mainChainSymbol == "BSC Testnet" && <ReceiveTBNB />} */}

          <div className="content"
          >{props.children}</div>
        </div>
      </div>
    </div>
  );
  const ChildLayout = props => (
    <>
      <div className="main-box">{props.children}</div>
    </>
  );

  const checkNetwork = network => {
    const supportedChainIds = config.supportedChainIds; // 当前支持的网络
    if (supportedChainIds.indexOf(Number(network)) === -1) {
      dispatch({ type: SWITCH_NETWORK.type, payload: { switchNetwork: true } });
    } else {
      // updateOpenSwitchNetworkModal(false);
      // updateNetwork(Number(network));
    }
    // setHasNetwork(true);
  };

  const getNetwork = async () => {
    if (window.ethereum) {
      const network = await window.ethereum.request({
        method: "eth_chainId"
      });

      checkNetwork(network);
    }
  };


  useEffect(() => {
    ReactGA.initialize(config.gtm);

    // 监听网络变化
    if (window.ethereum) {
      window.ethereum.on("networkChanged", network => {
        window.location.reload();
      });
    }
    getNetwork();
  }, []); // eslint-disable-line


  // useEffect(() => {
  //   dispatch({ type: SWITCH_NETWORK.type, payload: { switchNetwork: true } })
  // }, [])
  // 网络切换提示弹窗
  const openSwitchNetworkModal = useSelector(state => state.network);
  return (
    <Suspense fallback={<div>loading....</div>}>
      <BrowserRouter>
        {openSwitchNetworkModal.switchNetwork && (
          <SwitchNetworkModal
            visible={openSwitchNetworkModal.switchNetwork}
            onCancel={() =>
              dispatch({
                type: SWITCH_NETWORK.type,
                payload: { switchNetwork: false }
              })
            }
          />
        )}
        <GA></GA>
        <Switch>
          <Route path="/airDrop" component={AirDrop} />
          <Route path="/cryptoActivity" component={CryptoActivity} />
          <Route path="/ido" component={Ido} />

          <Route path="/" component={Layout}>
            <Layout>
              <Route exact path="/coinInsurance/pool" component={CoinPool} />
              {/* <Route path="/coinInsurance" component={TabCoinChildLayout}> */}
              {/* <TabCoinChildLayout> */}
              <Route exact path="/coinInsurance" component={Insurance} />
              <Route exact path="/coinInsurance/:coinTypeName/:insuranceCoinSymbol" component={Insurance} />
              <Route exact path="/coinInsurancelist" component={InsuranceList} />
              <Route exact path="/coinInsurance/policy" component={Mine} />
              {/* </TabCoinChildLayout> */}
              {/* </Route> */}

              <Route path="/insurance" component={ChildLayout}>
                <ChildLayout>
                  <Route exact path="/insurance/earn" component={Earn} />
                  <Route exact path="/insurance/pool" component={Pool} />
                  <Route exact path="/insurance" component={Home} />
                  <Route exact path="/insurance/policy" component={Policy} />
                  <Route exact path="/insurance/claim/contract" component={ClaimContract} />
                  <Route exact path="/insurance/claim" component={Claim} />
                  <Route
                    exact
                    path="/insurance/claimAssessor"
                    component={ClaimAssessor}
                  />
                  <Route
                    exact
                    path="/insurance/claimAssessor/voteHistory"
                    component={VoteHistory}
                  />
                  <Route
                    exact
                    path="/insurance/claimAssessorGovernment"
                    component={ClaimAssessorGovernment}
                  />

                  <Route
                    exact
                    path="/insurance/claimVote"
                    component={ClaimVote}
                  />
                </ChildLayout>
              </Route>

              <Route path="/more" component={ChildLayout}>
                <ChildLayout>
                  <Route exact path="/more/document" component={MoreDocument} />
                  <Route
                    exact
                    path="/more/recommend"
                    component={MoreRecommend}
                  />
                </ChildLayout>
              </Route>

              <Route exact path="/mining" component={Mining} />
              <Route exact path="/bond" component={Treasury} />
              <Route exact path="/bond/buy" component={BondBuy} />
              <Route exact path="/bond/my" component={MyBond} />
              <Route exact path="/bond/extract" component={BondExtract} />
              <Route path="/detail/:contractInsuranceName" component={Detail} />
              <Route exact path="/treasury/list" component={TreasuryList} />
              <Route exact path="/home" component={NodeDaoHome} />
              <Route exact path="/annuity" component={Annuity} />
              <Route exact path="/annuityIns" component={AnnuityIns} />
              <Route exact path="/annuityInsDetail/:pool/:insureId" component={AnnuityInsDetail} />
              <Route exact path="/" component={NonePage} />
              {/* <Redirect to="/home" /> */}
            </Layout>
          </Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
