import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import IntegralABI from "./abi/Integral.json";
import config from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class IntegralService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.integralContract = new this.web3.eth.Contract(
        IntegralABI,
        config.Integral
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  async getScore() {
    try {
      return await this.integralContract.methods
        .score()
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  async getPerDeci() {
    try {
      return await this.integralContract.methods
        .perDeci()
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  async getUserInfo() {
    const account = await this.getCurrentAccount();
    try {
      return await this.integralContract.methods
        .getUserInfo(account)
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async claim() {
    const account = await this.getCurrentAccount();
    try {
      return await this.integralContract.methods
        .claim()
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }


}

export default IntegralService;
