import React from "react";
import { Modal, Drawer } from "antd";

import "./index.scss";
import useDevice from "../../../hooks/useDevice";
import CloseModel from "./images/close-icon.png";

const CModal = ({
  visible,
  onCancel,
  width = 420,
  title,
  children,
  titlePosition = "center",
  titleFontSize = "30",
  maskClosable = true,
  showClose = true
}) => {
  const { isPc } = useDevice();

  return isPc ? (
    <Modal
      className="c-modal"
      visible={visible}
      centered
      footer={null}
      onCancel={onCancel}
      closable={false}
      maskClosable={maskClosable}
      width={width}
    >
      {showClose ? (
        <img
          src={CloseModel}
          onClick={onCancel}
          className="c-modal__close"
          alt=""
        />
      ) : (
        ""
      )}

      {title ? (
        <div
          className="c-modal__title"
          style={{ textAlign: titlePosition, fontSize: `${titleFontSize}px` }}
        >
          {title}
        </div>
      ) : (
        ""
      )}
      {children}
    </Modal>
  ) : (
    <Drawer
      className="c-drawer"
      visible={visible}
      title={title}
      placement="bottom"
      closable={true}
      onClose={onCancel}
      height={"auto"}
      zIndex={"9999"}
    >
      {children}
    </Drawer>
  );
};

export default CModal;
