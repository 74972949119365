export default {
  dappUrl: "https://app.marquee.fi/",
  siteUrl: "https://marquee.fi/",
  baseUrl: "https://api.marquee.fi/api/",
  documentUrl: "https://docs.marquee.fi/",
  documentUrlZH: "https://docs.marquee.fi/",
  inviteUrl: "https://app.marquee.fi/coinInsurancelist/",

  // 安全险
  ethRpc: "https://arb1.arbitrum.io/rpc",
  posRootERC20: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9", // USDT
  CSInsurance: "0xed8445d13eBfc93912a0C4A40357352d3Fa5817B",
  RewardPool: "0x5387733F5f457541a671Fe02923F146b4040530C",
  VoteDAO: "0xa134523f459990e546E32D85b0A93245CE3C2099",
  IPSTToken: '0x760204b6D610a648f9E45AF21B235A6AE2e9e74D', // CPST
  pendant: "0x71A8666a2c4aE8D548E2d2310031621d26028d6a",


  networkId: 42161, // arb正式
  blockExplorerUrls: "https://arbiscan.io", // 网站地址
  wsUrl: "wss://api.marquee.fi/api",
  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币地址
  mainChainSymbol: "ETH", // 主链币名称'
  mainChainName: "Arbitrum One",
  blockTime: 12, // BSC出一个块的时间(秒)
  // blockTime: 0.25, // BSC出一个块的时间(秒)

  usdtToken: {
    address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    symbol: "USDT",
    decimals: 6
  },
  v2LpToken: {
    address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    symbol: "v2LP",
    decimals: 6
  },

  // 币价险
  lpToken: {
    address: "0x16c6CAFD11B49E011e854c6DbA293e7363a0442E",
    symbol: "IPST",
    decimals: 6
  },
  erc721ContractAddr: "0xfAD8b0a07C591490B5cf39bB551b2860540C4338",
  rewardPoolContractAddr: "0x304829862C52BB4A4066e0085395E93439FAC657",
  insuranceContractAddr: "0xa24a56A55e67A8442e71252F31344Aeb4C71ef8a",
  InsurePay: "0x8A6bCcfDB7367Ba53Bd5739e3B22fc6c8a5F9339",
  Integral: "0xC23f4f8351E0fbdFaeF75A8fE957a628994Be633",
  marqueeInscription: "0xC038B4800ddc9e525aC4a0B388Aee5f18F5fb1E3",
  masterChefContractAddr: "0xF8EF514917a78b0A7E5E043983FFcEbe3BFcAa7d", // sunset
  ismLockPoolContractAddr: "0x0BB8584F5Bc236533a6A4790802104222D26B9F4", // sunset

  // 国库 债卷合约
  neiBondAddr: "0x7277b3F906698Fe21CA409F217677C81a2031694",
  neiVaultAddr: "0xeB4985942ec930df8d912205143Fbd9ACa122b13", // 国库

  // 年金保险
  Auth: "0x0e12a8b7c8f8DF545858F67bD3908D5c919B98b4",
  Factory: "0x9abba32eb14D9ddc467393E31Fb699AA813A5662",
  Algorithm: "0xD2a080EEDa13694aC03Fe1D31062B5d7e1036788",
  AnnuityData: "0xA02838f6A6F58d06031a024d5D32551F776e5f80",
  RouterV1: "0xfc2cbc1787a60E93F4656D52cBd2A6ef07F60662",
  ErrorContract: "0xCfb5aBBE5002573C06b7107b9762f15C709D8db7",
  // 交易
  Reward: "0x61b8b9C65bF8C5D8c958BBC338a9F03e26785463",

  nedToken: {
    address: "0x727E7A24Ad4fae30e37c3A23bEB08b8f4Cf1F375",
    symbol: "NED",
    decimals: 9
  },
  sNedToken: {
    address: "0xB49BB3bd49fF1A58e485b6d478638f9E767125c6",
    symbol: "sNED",
    decimals: 9
  },
  gNedToken: {
    address: "0xFA5952f1184082C6516C2253bF9548c5535fedCe",
    symbol: "gNED",
    decimals: 18
  },
  usdtToken: {
    address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    symbol: "USDT",
    decimals: 6
  },
  inviteMerkleDistributor: "0x504bb26C3fB4c0960Fd6C7E5EBddAfc8525C6e9E",

  oldMarq: "0xb2CCe9bDe4177B5A39a6ade5f3881Cb65D447D93",
  newMarq: "0x7A23af3A4B5fE509cCE22D0Ba02a983ea3D7f5D0",
  Trading: "0xbb60d418AB2C925C6c8A09a40812670CdD3bd131",
  MarqSwap: "0x33E2D81A7d2AD4aB41B5E422D548e5c40Ad20dB3",

  "twitterUrl": "https://twitter.com/Marquee_fi",
  "mediumUrl": "https://medium.com/@MarqueeOfficial",
  "discordUrl": "https://discord.com/invite/cp7akF4bg9",
  "telegramUrl": "https://t.me/MarqueeGroup",
  "gtm": "G-K9H76Q07BR",
};



// export default {
//   dappUrl: "https://test.marquee.fi/",
//   // dappUrl: "https://app.marquee.fi/",
//   siteUrl: "https://marquee.fi/",
//   baseUrl: "https://testapi.marquee.fi/",
//   // baseUrl: "https://api.marquee.fi/api/",
//   documentUrl: "https://docs.marquee.fi/",
//   documentUrlZH: "https://docs.marquee.fi/",
//   inviteUrl: "https://test.marquee.fi/coinInsurancelist/",
//   // inviteUrl: "https://app.marquee.fi/coinInsurancelist/",

//   // 安全险
//   ethRpc: "https://arb1.arbitrum.io/rpc",
//   posRootERC20: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9", // USDT
//   CSInsurance: "0x83F33758cD701d30a2982D30631852668e27d78E",
//   RewardPool: "0xbf4fA2C1C57BFECEbaf5D45d2aF23F796579C9BF",
//   VoteDAO: "0x1C597e09Abf88B27215A24207E308b304d7475e0",
//   IPSTToken: '0xFda81Ef345C90f7489d3Fb0d0511aa93c53dddA8', // CPST
//   pendant: "0xcE359C32fCB9F2eA8DA3e8A39c11B86aE1BF2fac",


//   networkId: 42161, // arb正式
//   blockExplorerUrls: "https://arbiscan.io", // 网站地址
//   wsUrl: "wss://testapi.marquee.fi",
//   // wsUrl: "wss://api.marquee.fi/api",
//   mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币地址
//   mainChainSymbol: "ETH", // 主链币名称'
//   mainChainName: "Arbitrum One",
//   blockTime: 0.25, // BSC出一个块的时间(秒)

//   usdtToken: {
//     address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
//     symbol: "USDT",
//     decimals: 6
//   },
//   v2LpToken: {
//     address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
//     symbol: "v2LP",
//     decimals: 6
//   },

//   // 币价险
//   lpToken: {
//     address: "0x34121A92Ea9155E45629aB9DFc728Bc473E6AcD6",
//     symbol: "IPST",
//     decimals: 6
//   },
//   erc721ContractAddr: "0x091c258BeEB82AcE5C541fb42C65C77fd51cc5Dc",
//   rewardPoolContractAddr: "0xF6CEd33323243701375833395fA33bB1D5e9780b",
//   insuranceContractAddr: "0x45D99Aa99776d4461a91114F4493E35b08444C03",
//   InsurePay: "0x18886cD04420c2F98448DdFdff545c18386098e0",
//   Integral: "0xCeBb7baA8c04C0243b40Ea519688F0B7c45Bce88",
//   marqueeInscription: "0xa7DBd97DA8c2F1B0c95ad527E83C619746c488D0",
//   masterChefContractAddr: "0xF8EF514917a78b0A7E5E043983FFcEbe3BFcAa7d", // sunset
//   ismLockPoolContractAddr: "0x0BB8584F5Bc236533a6A4790802104222D26B9F4", // sunset

//   // 国库 债卷合约
//   neiBondAddr: "0xDab26FAD51d19Ba95C41c57a143e37abE62BCe66",
//   neiVaultAddr: "0x0e0e9b55DD6f3092FA9652ba5B0B3ce2a85574f6", // 国库

//   // 年金保险
//   Auth: "0x9eaAd8Cf328e6Ac2416526E6f4555e28B6E56dC8",
//   Factory: "0x275EC77944A139Ce65a7E62c638c016aEf21733b",
//   Algorithm: "0x5B9cE333FEAe0F215EF15046F423b6C07B958d3A",
//   AnnuityData: "0x06e036EcA498013a97aa63c05732df79a43cd27a",
//   RouterV1: "0x81332f7a8b1BEFF8E444b6b608BA69BA79a7f92b",
//   ErrorContract: "0x1b5656079c8F4D6d514029c24400587054dE3Da6",

//   nedToken: {
//     address: "0x5654C01CFA57998d0b03A876E754797cC0DA192A",
//     symbol: "NED",
//     decimals: 9
//   },
//   sNedToken: {
//     address: "0xF5B5f065174BC7173553e991f32EfF7925508C79",
//     symbol: "sNED",
//     decimals: 9
//   },
//   gNedToken: {
//     address: "0xdc6cA1d44A9cCC6CEe9e63b5ffAe6A30139B71fA",
//     symbol: "gNED",
//     decimals: 18
//   },
//   usdtToken: {
//     address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
//     symbol: "USDT",
//     decimals: 6
//   },
//   inviteMerkleDistributor: "0x31E1ce9113da58761BBb0d637a8Fe9e155052A7a",

//   oldMarq: "0xb2CCe9bDe4177B5A39a6ade5f3881Cb65D447D93",
//   newMarq: "0x7A23af3A4B5fE509cCE22D0Ba02a983ea3D7f5D0",
//   Trading: "0xbb60d418AB2C925C6c8A09a40812670CdD3bd131",
//   MarqSwap: "0x33E2D81A7d2AD4aB41B5E422D548e5c40Ad20dB3",

//   "twitterUrl": "https://twitter.com/Marquee_fi",
//   "mediumUrl": "https://medium.com/@MarqueeOfficial",
//   "discordUrl": "https://discord.com/invite/cp7akF4bg9",
//   "telegramUrl": "https://t.me/MarqueeGroup",
//   "gtm": "G-K9H76Q07BR",
// };

