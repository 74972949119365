import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useMedia } from "react-use";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import config from '../../../config'
import "./index.scss";
import { shortenAddress } from "../../../utils";
import { showTip } from "../../common/CMessageTip";
import CModal from "../../common/CModal";

import Copy from '../../../assets/images/copy.png'
import View from '../../../assets/images/view.png'
import { ContractServe } from "../../../contracts";

const Account = props => {
  const { t } = useTranslation();
  const history = useHistory()
  const below960 = useMedia("(max-width: 960px)");

  const { activate, deactivate } = useWeb3React();
  const [account, setAccount] = useState('')

  useEffect(async () => {
    const res1 = await ContractServe.getCurrentAccount()
    setAccount(res1)
  }, [])

  function close() {
    props.setVisible(false)
  }

  return <CModal
    title={t('text')}
    footer={null}
    centered
    visible={props.visible}
    afterClose={close}
    onOk={() => props.setVisible(false)}
    onCancel={() => props.setVisible(false)}
    width={403}
  >
    <div className="account-content">
      <div className="box">
        <div className="btn-box flex align-center">
          <span className="ts-12 flex-1">{t('text1')}</span>
          <div className="btn" onClick={() => { deactivate(); props.setAccount(''); props.setVisible(false) }}>{t('text2')}</div>
        </div>
        <p className="mt-18 ts-20">{shortenAddress(account)}</p>
        <div className="account_footer mt-18">
          <div className="flex align-center point opacity-8" onClick={() => {
            copy(account);
            showTip(
              "copy",
              "success",
              t('common.复制成功'),
              ''
            );
          }}>
            <img src={Copy} alt="" />
            <span className="ts-12">{t('text3')}</span>
          </div>
          <div className="flex align-center point opacity-8" onClick={() => { window.open(config.blockExplorerUrls) }}>
            <img src={View} style={{ marginLeft: 50 }} alt="" />
            <span className="ts-12">{t('common.在BSC上查看', { name: config.mainChainName })}</span>
          </div>
        </div>
      </div>
    </div>
  </CModal>
};

export default Account;
