import BigNumber from "bignumber.js";
import i18next from "i18next";

export function shortenAddress(address, chars = 6) {
  return address
    ? `${address.substring(0, chars)}...${address.substring(
      address.length - chars
    )}`
    : "";
}

export function getQueryVariable(query, variable) {
  var vars = query.split("?")[1].split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

export function formatTime(second) {
  const day = second / 86400
  const month = 30
  const year = 365
  const twoYear = year * 2

  if (day < 30) {
    return `${day}${i18next.t('text47')}`
  }
  if (day < year) {
    return `${day / month}${i18next.t('Month')}`
  } else {
    return `${day / year}${i18next.t('year')}`
  }

  // if (day > twoYear) {
  //   // 大于两年
  //   const y = Math.trunc(day / year)
  //   const m = day / month - y * 12
  //   return `${y}年${m}月`
  // } else {
  //   const m = day / month
  //   const mD = Math.trunc(day / month)
  //   if (mD == m) {
  //     return `${day / month}月`
  //   } else {
  //     return `${mD}月${m * month}天`
  //   }
  // }
}


// 精度转化 乘
export function nToBN(value, decimals = 18) {
  return new BigNumber(value).times(new BigNumber(10).pow(decimals)).toString();
}

// 精度转化 除
export function bNToN(value, decimals = 18) {
  return new BigNumber(value).div(new BigNumber(10).pow(decimals)).toString();
}
// 截取小数
export const decimalBit = (num, count = 2, absoluteZero = false, separator = false) => {

  if (isNaN(num) && num) {
    return num;
  }
  if (!num || num == " " || num == 0) {
    if (count == 0 || absoluteZero == true) {
      return 0;
    }
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `0.${fillZero}`;
  }

  const numList = String(num).split(".");
  if (count === 0) {
    if (separator) {
      return Number(numList[0]).toLocaleString('en-US');
    }
    return Number(numList[0]);
  }
  if (numList.length > 1) {
    const decimalAfterQuantity = `${String(numList[1]).substr(0, count)}`;
    let fillZero = "";
    const fillZeroQuantity = count - decimalAfterQuantity.length;
    if (fillZeroQuantity > 0) {
      for (let i = 0; i < fillZeroQuantity; i++) {
        fillZero += "0";
      }
    }
    if (separator) {
      return `${Number(numList[0]).toLocaleString('en-US')}.${decimalAfterQuantity}${fillZero}`;
    }
    return `${Number(numList[0])}.${decimalAfterQuantity}${fillZero}`;
  } else {
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    if (separator) {
      return `${Number(num).toLocaleString('en-US')}.${fillZero}`;
    }
    return `${num}.${fillZero}`;
  }
};

// 获取精度
export const nowPriceDec = (nowPrice) => {
  nowPrice = Number(nowPrice)
  if (nowPrice < 0.0002) {
    return 8
  } else {
    return 6
  }
  // if (nowPrice < 0.0002) {
  //   return 8
  // } else if (nowPrice < 0.02) {
  //   return 6
  // } else if (nowPrice < 2) {
  //   return 4
  // } else {
  //   return 2
  // }
}

export const formateMoney = (num, count = 2) => {
  if (isNaN(num) && num) {
    return num;
  }
  if (!num || num === " " || num === 0) {
    if (count === 0) {
      return 0;
    }
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `0.${fillZero}`;
  }

  const numList = String(num).split(".");
  if (count === 0) {
    return Number(numList[0]).toLocaleString();
  }
  if (numList.length > 1) {
    const decimalAfterQuantity = `${String(numList[1]).substr(0, count)}`;
    let fillZero = "";
    const fillZeroQuantity = count - decimalAfterQuantity.length;
    if (fillZeroQuantity > 0) {
      for (let i = 0; i < fillZeroQuantity; i++) {
        fillZero += "0";
      }
    }
    return `${Number(
      numList[0]
    ).toLocaleString()}.${decimalAfterQuantity}${fillZero}`;
  } else {
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `${Number(num).toLocaleString()}.${fillZero}`;
  }
};
// 获取单位
export const getUnit = decimal => {
  const wei =
    decimal == 3
      ? "kwei"
      : decimal == 6
        ? "mwei"
        : decimal == 9
          ? "gwei"
          : decimal == 12
            ? "szabo"
            : decimal == 15
              ? "finney"
              : decimal == 18
                ? "ether"
                : "ether";
  return wei;
};
// 处理科学计数法
export function toolNumber(num_str) {
  num_str = num_str.toString();
  if (num_str.indexOf("+") != -1) {
    num_str = num_str.replace("+", "");
  }
  if (num_str.indexOf("E") != -1 || num_str.indexOf("e") != -1) {
    var resValue = "",
      power = "",
      result = null,
      dotIndex = 0,
      resArr = [],
      sym = "";
    var numStr = num_str.toString();
    if (numStr[0] == "-") {
      //如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
      numStr = numStr.substr(1);
      sym = "-";
    }
    if (numStr.indexOf("E") != -1 || numStr.indexOf("e") != -1) {
      var regExp = new RegExp(
        "^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$",
        "ig"
      );
      result = regExp.exec(numStr);
      if (result != null) {
        resValue = result[2];
        power = result[5];
        result = null;
      }
      if (!resValue && !power) {
        return false;
      }
      dotIndex = resValue.indexOf(".") == -1 ? 0 : resValue.indexOf(".");
      resValue = resValue.replace(".", "");
      resArr = resValue.split("");
      if (Number(power) >= 0) {
        var subres = resValue.substr(dotIndex);
        power = Number(power);
        //幂数大于小数点后面的数字位数时，后面加0
        for (var i = 0; i <= power - subres.length; i++) {
          resArr.push("0");
        }
        if (power - subres.length < 0) {
          resArr.splice(dotIndex + power, 0, ".");
        }
      } else {
        power = power.replace("-", "");
        power = Number(power);
        //幂数大于等于 小数点的index位置, 前面加0
        for (var i = 0; i < power - dotIndex; i++) {
          resArr.unshift("0");
        }
        var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
        resArr.splice(n, 0, ".");
      }
    }
    resValue = resArr.join("");
    return sym + resValue;
  } else {
    return num_str;
  }
}
// 位数变除数
export const unit = num => {
  let a = '1'
  for (let i = 0; i < num; i++) {
    a = a + '0'

  }
  return a
}


// 获取当前日期周一
export function getFirstDayOfWeek(timestamp) {
  const date = new Date(timestamp * 1000);
  const day = date.getDay() || 7;
  return (
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1 - day
    ).getTime() / 1000
  );
}

// 获取当前日期周日
export function getLastDayOfWeek(timestamp) {
  const date = new Date(timestamp * 1000);
  const day = date.getDay() || 7;
  return (
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 7 - day
    ).getTime() / 1000
  );
}

export function getH(s) {
  s = Number(s)
  const h = String(s / 3600)
  var r = h.split('.')
  return r[0]
}


export function getM(s) {
  s = Number(s)
  const h = String(s / 3600)
  var r = h.split('.')
  if (r.length > 1) {
    var e = s - r[0] * 3600
    var w = String(e / 60)
    r = w.split('.')
    return r[0]
  } else {
    return '0'
  }
}

export function getS(s) {
  s = Number(s)
  const h = String(s / 3600)
  var r = h.split('.')
  if (r.length > 1) {
    var e = s - r[0] * 3600
    var w = String(e / 60)
    var mr = w.split('.')

    if (mr.length > 1) {
      var e = s - r[0] * 3600 - mr[0] * 60

      return e
    } else {
      return '0'
    }

  } else {
    return '0'
  }
}

var SI_SYMBOL = ["", "K", "M", "B", "T", "Q"];

export function abbreviateNumber(number, limit) {

  // what tier? (determines SI symbol)
  var tier = Math.log10(Math.abs(number)) / 3 | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(limit) + suffix;
}

export function copyText(text) {
  navigator.clipboard.writeText(text);
}

export function displayEffective(num, displayDec = false) {
  num = Math.abs(Number(num))
  if (!num || num == '...' || Number(num) == 0) {
    return 2
  }
  const strNum = num.toFixed(30).split('.')
  // const strNum = String(num).split('.')
  const strNum1 = strNum[0]
  const strNum2 = strNum[1]
  if (Number(strNum1) > 0) {
    return displayDec ? displayDec : 2
  } else {
    let flag = 0
    if (strNum2) {

      for (let i = 0; i < strNum2.length; i++) {
        if (strNum2[i] == 0) {
          flag = flag + 1
        } else {
          const end = displayDec ? displayDec : 4
          flag = flag + end
          break
        }
      }
    } else {
      flag = 2
    }
    return flag
  }
}