import axios from "axios";
import config from "../config";
import { showTip } from '../components/common/CMessageTip'
import i18next from "i18next";

let axiosRequestArr = [];
let _option = {
  hasProgress: true // 显示进度条的效果
};

axios.defaults.baseURL = config.baseUrl;
// 请求拦截器
axios.interceptors.request.use(
  config => {

    // 如果请求设置了cancelToken
    config.cancelToken = config.cancelToken
      ? config.cancelToken
      : new axios.CancelToken(cancel => {
        axiosRequestArr.push(cancel);
      });

    return config;
  },
  error => {
    console.log(error);
    return Error("服务请求报错");
  }
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    axiosRequestArr.pop();
    if (response.data.code === 0) {
      return response.data.data;
    }
    if (response.data.code === 100422) {
      return {};
    }
    if (response.data.code === 100437) {
      return response.data;
    }
    if (response.data.code === 100439) {
      return response.data;
    }
    if (response.data.code === 100443) {
      return response.data;
    }
    if (response.data.code === 100444) {
      return response.data;
    }
    if (response.data.data) {
      return response.data
    }
  },
  // 服务器状态码不是200的情况
  error => {
    axiosRequestArr.pop();
    if (!axios.isCancel(error)) {
      showTip(
        "http",
        "error",
        i18next.t('网络错误'),
        ''
      );
    }
  }
);

export function post(url, data, option) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: url,
      data: data,
      ..._option,
      ...option
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function get(url, data, option) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: url,
      params: data,
      ..._option,
      ...option
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function all(axiosFuns) {
  return new Promise(resolve => {
    axios.all(axiosFuns).then(
      axios.spread((...resArr) => {
        resolve(resArr);
      })
    );
  });
}

export function getIdo(url, data, option) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: url,
      params: data,
      ..._option,
      ...option
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function postIdo(url, data, option) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data: data,
      ..._option,
      ...option
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

// https://api.fvlaunch.io/api/launchpad/detail
export default {
  post,
  get,
  all,
  getIdo,
  postIdo,
  CancelToken: axios.CancelToken
};
