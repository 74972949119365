// arb 测试
export default {
  dappUrl: "http://47.241.66.95/marquee-dapp-dev-arb/",
  siteUrl: "https://marquee.fi/",
  baseUrl: "https://testapi.marquee.fi",
  documentUrl: "https://docs.marquee.fi/",
  documentUrlZH: "https://docs.marquee.fi/",
  inviteUrl: "http://47.241.66.95/marquee-invite-dev-arb/",

  // 安全险
  ethRpc: "https://bsc-testnet.public.blastapi.io",
  posRootERC20: "0x36135829766eA4ccf87D0655704853a53DEabBA7", // USDT
  CSInsurance: "0xf97663448Bb4689a37546B7Eb76ba58233FAf295",
  RewardPool: "0xbba03f8aAa1C3F2CEA718f5940F6eaceb8b91651",
  VoteDAO: "0x5D1bd875FC7e7EF39030875BC21De9B6E1ABBb73",
  IPSTToken: '0xD94145fCc5A8E0ebcb1eDD49854142a97bfeEf14', // CPST
  pendant: "0x86b9f68C63303f23bCaea2A14de124af790517d1",


  networkId: 421614, // 测试
  blockExplorerUrls: "https://testnet.bscscan.com", // 网站地址
  wsUrl: "wss://ism-client-bsc-magnify.nodedao.io",
  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币地址
  mainChainSymbol: "ETH", // 主链币名称
  blockTime: 15, // BSC出一个块的时间(秒)

  ismAddr: "0x14A043259983a6a9a65b4F536902a8359E749607",

  usdtToken: {
    address: "0x36135829766eA4ccf87D0655704853a53DEabBA7",
    symbol: "USDT",
    decimals: 6
  },
  v2LpToken: {
    address: "0x36135829766eA4ccf87D0655704853a53DEabBA7",
    symbol: "v2LP",
    decimals: 6
  },

  // 币价险
  lpToken: {
    address: "0xd2A9C3bb7962d2F92b59d4A0491f670b769fEF16",
    symbol: "IPST",
    decimals: 6
  },
  erc721ContractAddr: "0xC52E691F6CE742dEB882A8c459c2270bb4CB4E29",
  rewardPoolContractAddr: "0xc5Bf3209428936a6B6848992CCb4Aa8D4DAC5994",
  insuranceContractAddr: "0x5B70A4fC3789937e579790048F5063c542d20f3A",
  InsurePay: "0x74eF8D036b72A378B5bB08176b51E7aFAEc8ec61",
  Integral: "0x1ac90D9C53b828f46c5d3a9BbD6d0B2E429E207D",
  marqueeInscription: "0x1ABa40F11fdc3AeeA0ADfD123170fbbfa926fd94",
  masterChefContractAddr: "0xF8EF514917a78b0A7E5E043983FFcEbe3BFcAa7d", // sunset
  ismLockPoolContractAddr: "0x0BB8584F5Bc236533a6A4790802104222D26B9F4", // sunset

  // 国库 债卷合约
  neiBondAddr: "0x8ea10aB10033cB9ffb8053A52A35C39482cfbEa2",
  neiVaultAddr: "0xd82E87fF58dB43a2B96c901E18E47aDf031e92a9", // 国库

  // 年金保险
  Auth: "0x0F41Bd732C445E8Dbe0056EBAA625EeC69F4bfaA",
  Factory: "0x69E4a9FeED03F221d2471eA6317D7E4eCdE6db2F",
  Algorithm: "0xC24de9280a15F5ab3842ce512605139aC1F581A9",
  AnnuityData: "0xE10d3bbF6AA4EbF6D36287e4daA4CaAbD1Fe68C2",
  RouterV1: "0x27C547019177695235d2bd1bC1636C10713a2152",
  ErrorContract: "0x06342ACbb198eb2ddfEE655cd241b1B523b68F21",

  nedToken: {
    address: "0xB9b2810A9163d102CFA97892b011A6E694649E02",
    symbol: "NED",
    decimals: 9
  },
  sNedToken: {
    address: "0x1FB7b0fcE141C483B7A97932071Bba7afb1A129d",
    symbol: "sNED",
    decimals: 9
  },
  gNedToken: {
    address: "0x7Ff2E818e86F6fBdC2b280E275bDD9D977833a8E",
    symbol: "gNED",
    decimals: 18
  },
  usdtToken: {
    address: "0x36135829766eA4ccf87D0655704853a53DEabBA7",
    symbol: "USDT",
    decimals: 6
  },
  inviteMerkleDistributor: "0x04ed7EFfFe47c397188287E1dbc3dF7398689507",

  // 挖矿
  Bonus: "0xA397744e9e80b0E7dBD3cAEC4A445BA78756A7E7",
  // IDO 平台
  idozenix: "0x1b28AEdacfB3Abf79db8a1b8bd2A85E2f60aD79E",
  ido: {
    matic: {
      rpc: 'https://polygon-rpc.com/',
      chainId: 137,
      address: "0x5e2aFf47347d992E8F9Ad07A3cdad73C77ebB982"
    },
    bsc: {
      rpc: 'https://bsc-dataseed.binance.org/',
      chainId: 56,
      address: "0xDd0Cb33e6b0a904D522084d98a2e524003D499F4"
    },
    arb: {
      rpc: 'https://arb1.arbitrum.io/rpc',
      chainId: 42161,
      address: "0x890FC597E701eaDA882DDe364b9e7D134E24c555"
    }
  },

  oldMarq: "0xb2CCe9bDe4177B5A39a6ade5f3881Cb65D447D93",
  newMarq: "0x7A23af3A4B5fE509cCE22D0Ba02a983ea3D7f5D0",
  Trading: "0xbb60d418AB2C925C6c8A09a40812670CdD3bd131",
  MarqSwap: "0x33E2D81A7d2AD4aB41B5E422D548e5c40Ad20dB3",
  "twitterUrl": "https://twitter.com/Marquee_fi",
  "mediumUrl": "https://medium.com/@MarqueeOfficial",
  "discordUrl": "https://discord.com/invite/cp7akF4bg9",
  "telegramUrl": "https://t.me/MarqueeGroup",
  "gtm": "G-K9H76Q07BR",
};
