import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import RouterV1ABI from "./abi/RouterV1ABI";
import config from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class RouterV1Service extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.routerV1Contract = new this.web3.eth.Contract(
        RouterV1ABI,
        config.RouterV1
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  // 购买
  async purchaseInsuranceFor(pool, invest, amount) {
    const account = await this.getCurrentAccount();
    try {
      return await this.routerV1Contract.methods
        .purchaseInsuranceFor(pool, invest, amount)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  // 到期申请
  async appealFor(pool, orderId) {
    const account = await this.getCurrentAccount();
    try {
      return await this.routerV1Contract.methods
        .appealFor(pool, orderId)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  // 领取分红
  async claim(pool, orderId) {
    const account = await this.getCurrentAccount();
    try {
      return await this.routerV1Contract.methods
        .claim(pool, orderId)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }
}

export default RouterV1Service;
