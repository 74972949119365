import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import TradingABI from "./abi/Trading.json";
import config from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class TradingService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.tradingContract = new this.web3.eth.Contract(
        TradingABI,
        config.Trading
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  async getUserTxInfo() {
    const account = await this.getCurrentAccount();
    try {
      return await this.tradingContract.methods
        .getUserTxInfo(account)
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }


  async startTime() {
    try {
      return await this.tradingContract.methods
        .startTime()
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  async getCurrTime() {
    try {
      return await this.tradingContract.methods
        .getCurrTime()
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async claim() {
    const account = await this.getCurrentAccount();
    try {
      return await this.tradingContract.methods
        .claim()
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }


}

export default TradingService;
