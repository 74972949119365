import React, { useEffect, useState } from "react";
import { useMedia } from "react-use";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import { Modal, Drawer, Tooltip } from "antd";
import config from "../../config";
import "./index.scss";

// new ---------
import LogoNew from "./img/logo.png";
import HuoImg from "./img/huo.png"
import Down from "./img/black-arrow-down.png";
import Up from "./img/blue-arrow-up.png";
import Right from "./img/blur-arrow-right.png";
import Twitter from "./img/twitter.png";
import Medium from "./img/medium.png";
import GitBook from "./img/gitbook.svg";
import Discord from "./img/discord.png";
import Telegram from "./img/telegram.png";
import DashboardBlack from "./img/dashboard-black.png";
import DashboardBlue from "./img/dashboard-blue.png";
import BuyBlack from "./img/buy-black.png";
import BuyBlue from "./img/buy-blue.png";
import BuyCoverBlack from "./img/buy-cover-black.png";
import BuyCoverBlue from "./img/buy-cover-blue.png";
import EarnBlack from "./img/earn-black.png";
import EarnBlue from "./img/earn-blue.png";
import ClaimsBlack from "./img/Claims-black.png";
import ClaimsBlue from "./img/Claims-blue.png";
import AssessorBlack from "./img/assessor-black.png";
import AssessorBlue from "./img/assessor-blue.png";
import MoreBlack from "./img/more-black.png";
import MoreBlue from "./img/more-blue.png";
import BurgerBlue from "./img/burger-blue.png";
import WalletBlue from "./img/wallet-blue.png";
import CampaignBlack from "./img/campaign-black.png";
import CampaignBlue from "./img/campaign-blue.png";
// new ---------

import { shortenAddress } from "../../utils/index";

import ConfirmDialog from "../dialog/confirmDialog";
import LinkDialog from "../dialog/linkDialog";
import AccountDialog from "../dialog/accountDialog";
import { ContractServe } from "../../contracts";
import useDevice from "../../hooks/useDevice";
import i18next from "i18next";
import H5SelectLang from '../../components/H5SelectLang'
import { clearImageViewer } from "antd-mobile/es/components/image-viewer/methods";

import { getTestUSDT, getTestMarq, setIp } from '../../api'
import { showTip, closeTip } from "../../components/common/CMessageTip"

import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import { sendAnalytic } from "../../utils/analytic";

const Nav = props => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { activate, active } = useWeb3React();
  const web3reactData = useWeb3React();
  const below960 = useMedia("(max-width: 960px)");
  const [open, setOpen] = useState(false);

  const { isPc, isMobile } = useDevice();
  const [account, setAccount] = useState("");
  const [visible, setVisible] = useState(false);
  const [linkVisible, setLinkVisible] = useState(false);
  const [accountVisible, setAccountVisible] = useState(false);

  const [showMenuIndex, setShowMenuIndex] = useState(-1);
  const [showTwoMenuIndex, setShowTwoMenuIndex] = useState(false);
  const [showActiveMenuIndex, setShowActiveMenuIndex] = useState(-1);
  const [parentOfChild, setParentOfChild] = useState(-1);
  const [showMenuChildIndex, setShowMenuChildIndex] = useState(-1);

  useEffect(() => {
    init();
  }, []);

  useEffect(
    () => {
      initActiveIndex();
    },
    [location]
  )

  async function init() {
    const account1 = await ContractServe.getCurrentAccount();
    if (!active) {
      link();
    }
    if (account1) {
      setAccount(account1);
    }
    setIp({ user: account1 })
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        // Time to reload your interface with accounts[0]!
        setAccount(accounts[0]);
        setIp({ user: accounts[0] })
        setLinkVisible(false);
        const arr = [
          "/insurance",
          "/insurance/pool",
          "/insurance/policy",
          "/insurance/claim",
          "/cryptoActivity"
        ];
        const pathname = window.location.href.split("#")[1];
        if (arr.includes(pathname)) {
          window.location.reload();
        } else {
          showChildMenu(null, {
            icon: DashboardBlack,
            iconActive: DashboardBlue,
            title: t("数据面板"),
            path: "/home",
            activeArr: ["/home"]
          }, 0, false, undefined)
        }
      });
    }
  }

  function initActiveIndex() {
    newList.forEach((el, index) => {
      if (el.activeArr.includes(pathname)) {
        setShowMenuIndex(index);
        setShowActiveMenuIndex(index)
      }
      if (el.children) {
        el.children.forEach((ele, idx) => {
          if (ele.activeArr.includes(pathname)) {
            setShowMenuChildIndex(idx);
            setParentOfChild(index)
          }
        });
      }
    });
  }

  const link = async () => {
    if (account) {
      return;
    }
    await activate(config.injected);
  };

  const newList = [
    {
      icon: DashboardBlack,
      iconActive: DashboardBlue,
      title: t("数据面板"),
      path: "/home",
      activeArr: ["/home"]
    },
    {
      icon: BuyCoverBlack,
      iconActive: BuyCoverBlue,
      title: t("购买保险"),
      path: "/insurance",
      activeArr: ["/insurance", "/detail", "/coinInsurance", "/coinInsurancelist"],
      children: [
        {
          title: t("币价保险"),
          path: "/coinInsurancelist",
          activeArr: ["/coinInsurance", "/coinInsurancelist"]
        },
        {
          title: t("合约保险"),
          path: "/insurance",
          activeArr: ["/insurance", "/detail"]
        },
        // {
        //   title: t("Annuity"),
        //   path: "/annuityIns",
        //   activeArr: ["/annuityIns", "/annuityInsDetail"]
        // }
      ]
    },
    {
      icon: EarnBlack,
      iconActive: EarnBlue,
      title: t("理财"),
      activeArr: ["/insurance/earn", "/insurance/pool", "/insurance/pool", "/bond"],
      children: [
        {
          title: t("质押"),
          path: "/insurance/earn",
          activeArr: ["/insurance/earn"],
        },
        {

          title: t('text109'),
          // path: "/coinInsurance/pool",
          activeArr: ["/coinInsurance/pool", "/insurance/pool"],
          childrens: [
            {
              title: t('币价保险'),
              path: "/coinInsurance/pool",
              activeArr: ["/coinInsurance/pool"]
            },
            {
              title: t('合约保险'),
              path: "/insurance/pool",
              activeArr: ["/insurance/pool"]
            },
          ]
        },
        {
          title: t("债券"),
          path: "/bond",
          activeArr: ["/bond"]
        },
        {
          title: t("交易挖矿"),
          path: "/mining",
          activeArr: ["/mining"]
        },
        // {
        //   title: t("债券"),
        //   path: "/bond",
        //   activeArr: ["/bond"]
        // },
        // {
        //   title: t("Annuity"),
        //   path: "/annuity",
        //   activeArr: ["/annuity"]
        // }
      ]
    },
    {
      icon: ClaimsBlack,
      iconActive: ClaimsBlue,
      title: t("索赔"),
      path: "/insurance/claim",
      activeArr: ["/insurance/claim"]
    },
    {
      icon: AssessorBlack,
      iconActive: AssessorBlue,
      title: t('索赔评估员'),
      path: "/insurance/claimAssessor",
      activeArr: ["/insurance/claimAssessor", "/insurance/claimAssessorGovernment"]
    },
    // {
    //   icon: AssessorBlack,
    //   iconActive: AssessorBlue,
    //   title: t("索赔评估"),
    //   path: "/withdraw",
    //   activeArr: ["/withdraw"]
    // },
    {
      icon: CampaignBlack,
      iconActive: CampaignBlue,
      title: t("热门活动"),
      path: "/campaign",
      activeArr: ["/campaign/launching"],
      children: [
        {
          title: t('campaign.launching'),
          path: "/cryptoActivity",
          activeArr: ["/cryptoActivity"],
        },
        {
          title: t('common.推荐'),
          path: "/more/recommend",
          activeArr: ["/more/recommend"]
        },
      ]
    },
    {
      icon: MoreBlack,
      iconActive: MoreBlue,
      title: t('text163'),
      path: "/more",
      activeArr: ["/more/document", "/more/recommend"],
      children: [
        {
          title: t('common.文档'),
          path: "/more/document",
          activeArr: ["/more/document"],
          // jump: config.documentUrl
          jump: i18n.language.toUpperCase() == 'ZH' ? config.documentUrlZH : config.documentUrl
        },
        {
          title: t('globle_演示文稿'),
          path: "/more/pitchDeck",
          activeArr: ["/more/pitchDeck"],
          // jump: config.documentUrl
          jump: i18n.language.toUpperCase() == 'ZH' ? 'https://bit.ly/45Q9Tq3' : 'https://bit.ly/45Q9Tq3'
        },
        {
          title: t('globle_白皮书'),
          path: "/more/whitePaper",
          activeArr: ["/more/whitePaper"],
          // jump: config.documentUrl
          jump: i18n.language.toUpperCase() == 'ZH' ? 'https://marquee.fi/whitepaper.pdf' : 'https://marquee.fi/whitepaper.pdf'
        },

        // {
        //   title: t('receive_token', { token: "MARQ" }),
        //   path: "/more/document",
        //   activeArr: ["/more/document"],
        //   do: () => {
        //     if (account) {
        //       getTestMarq({ account: account }).then((res) => {
        //         if (typeof res == "string") {
        //           showTip('receiveMarq', 'success', t('receive_token_success', { token: "MARQ" }))
        //         } else {
        //           if (res.code == 100437) {
        //             let timing = res.code.split("left until next allowance")[0]
        //             if (i18n.language.toUpperCase() == 'ZH') {
        //               timing = timing.replace("h", t("text113"))
        //               timing = timing.replace("m", t("text114"))
        //               timing = timing.replace("s", t("text115"))
        //             }
        //             showTip('receiveMarq', 'error', t('receive_token_time_left', { time: timing }))
        //           } else {
        //             showTip('receiveMarq', 'error', t('receive_token_fail', { token: "MARQ" }))
        //           }
        //         }
        //       }).catch((err) => {
        //         showTip('receiveMarq', 'error', t('receive_token_fail', { token: "MARQ" }))
        //       })
        //     } else {
        //       showTip('receiveMarq', 'error', t('no_address_detected'))
        //     }
        //   }
        // },
        // {
        //   title: t('receive_token', { token: "TUSDT" }),
        //   path: "/more/document",
        //   activeArr: ["/more/document"],
        //   do: () => {
        //     if (account) {
        //       getTestUSDT({ account: account }).then((res) => {
        //         if (res) {
        //           showTip('receiveTUSDT', 'success', t('receive_token_success', { token: "TUSDT" }))
        //         } else {
        //           showTip('receiveTUSDT', 'error', t('receive_token_fail_received', { token: "TUSDT" }))
        //         }
        //       }).catch((err) => {
        //         showTip('receiveTUSDT', 'error', t('receive_token_fail', { token: "TUSDT" }))
        //       })
        //     } else {
        //       showTip('receiveTUSDT', 'error', t('no_address_detected'))
        //     }
        //   }
        // },
        // {
        //   title: t('receive_token', { token: "Gas Fee" }),
        //   path: "/more/document",
        //   activeArr: ["/more/document"],
        //   // jump: config.documentUrl
        //   jump: i18n.language.toUpperCase() == 'ZH' ? `${config.dappUrl}BSCTestnetGas费用领取流程.pdf` : `${config.dappUrl}BSCTestnetGasfeeCollectionProcess.pdf`
        // }
      ]
    }
  ];

  const showChildMenu = (e, el, index, isHaveChild, isChild) => {
    if (e) { e.stopPropagation() };
    if (el.jump) {
      window.open(el.jump)
      onClose();
      return
    }
    if (el.do) {
      el.do()
      onClose();
      return
    }
    if (isChild) {

      if (showMenuChildIndex === index && parentOfChild == showMenuIndex) {
        return;
      }
      setShowMenuChildIndex(index);
      setParentOfChild(showMenuIndex);
      history.push(el.path);
      onClose();
      return;
    }
    // setShowMenuChildIndex(-1);
    if (showActiveMenuIndex === index) {
      setShowActiveMenuIndex(-1);
      return
    } else {
      if (index != 100) {

        setShowMenuIndex(index);
        setShowActiveMenuIndex(index)
      }
    }
    if (!isHaveChild) {
      setParentOfChild(-1);
      history.push(el.path);
      onClose();
    }
  };

  const Menu = ({ data, isChild, index: pindex }) => {
    return (
      <div
        className={[
          isChild ? "child-menu" : "",
          "menu",
          pindex && showActiveMenuIndex === pindex ? "show" : ""
        ].join(" ")}
      >
        {data.map((el, index) => {
          if (el.childrens) {

            return (

              <div className="item-box" key={"menu_" + index}>
                {showMenuIndex === index && !isChild ? (
                  <img className="menu-active" src={Right} alt="" />
                ) : (
                  ""
                )}
                <div
                  className={[
                    "item",
                    "item_flex_sp",
                    (isChild) ? "grey-bg" : "",
                    (showMenuIndex === index && !isChild) ? "active-single" : "",
                    (showMenuIndex === index && !isChild) ||
                      (showMenuChildIndex === index && isChild && pindex == parentOfChild)
                      ? "active-color"
                      : ""
                  ].join(" ")}
                  key={el.title}
                  // 后续去掉
                  style={
                    el.activeArr.includes('/coinInsurance/pool') && showTwoMenuIndex ?
                      {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,

                      } : {}}
                  onClick={() => {
                    setShowTwoMenuIndex(!showTwoMenuIndex)
                  }}
                >
                  {el.title}
                  <span>
                    {
                      showTwoMenuIndex ? '-' : '+'
                    }</span>
                </div>
                {
                  showTwoMenuIndex ?

                    <div className={["item", "grey-bg"].join(' ')}>
                      {
                        el.childrens.map(item => (

                          <div className={["item_chs", pathname == item.path ? 'active-color' : ''].join(' ')}

                            onClick={e => showChildMenu(e, item, 100, false, false)}
                          >{item.title}
                            {item.path == '/coinInsurance/pool' ? <img className="huo" src={HuoImg} /> : ''}
                          </div>
                        ))
                      }
                    </div> : ''
                }
              </div>
            );
          } else if (el.children) {
            return (
              <div className="item-box" key={"menu_" + index}>
                {showMenuIndex === index && !isChild ? (
                  <img className="menu-active" src={Right} alt="" />
                ) : (
                  ""
                )}
                <div
                  className={[
                    "item",
                    showActiveMenuIndex == -1 ? "block-bottom-menu" : "",
                    showMenuIndex === index ? "grey-bg" : "",
                    showMenuIndex === index ? "active-color" : "",
                    "block-menu"
                  ].join(" ")}
                  onClick={e => showChildMenu(e, el, index, true, false)}
                >
                  {el.icon ? (
                    <img
                      className="icon"
                      src={showMenuIndex === index ? el.iconActive : el.icon}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  {el.title}
                  {showActiveMenuIndex === index ? (
                    <img className="arrow" src={Up} alt="" />
                  ) : (
                    <img className="arrow" src={Down} alt="" />
                  )}
                </div>
                <Menu data={el.children} index={index} isChild={true} />
              </div>
            );
          } else {
            return (
              <div className="item-box" key={"menu_" + index}>
                {showMenuIndex === index && !isChild ? (
                  <img className="menu-active" src={Right} alt="" />
                ) : (
                  ""
                )}
                <div
                  className={[
                    "item",
                    (isChild) ? "grey-bg" : "",
                    (showMenuIndex === index && !isChild) ? "active-single" : "",
                    (showMenuIndex === index && !isChild) ||
                      (showMenuChildIndex === index && isChild && pindex == parentOfChild)
                      ? "active-color"
                      : ""
                  ].join(" ")}
                  key={el.title}
                  onClick={e => showChildMenu(e, el, index, false, isChild)}
                >
                  {el.icon ? (
                    <img
                      className="icon"
                      src={showMenuIndex === index ? el.iconActive : el.icon}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  {el.title}

                  {el.path == '/coinInsurancelist' ? <img className="huo" src={HuoImg} /> : ''}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const DrawerMenu = ({ data, isChild, index: pindex }) => {
    return (
      <div
        className={[
          isChild ? "child-menu" : "",
          "menu",
          pindex && showActiveMenuIndex === pindex ? "show" : ""
        ].join(" ")}
      >
        {data.map((el, index) => {
          if (el.childrens) {
            return (
              <div className="item-box" key={"menu_" + index}>
                {showMenuIndex === index && !isChild ? (
                  <img className="menu-active" src={Right} alt="" />
                ) : (
                  ""
                )}
                <div
                  className={[
                    "item",
                    "item_flex_sp",
                    (showMenuIndex === index && !isChild) ||
                      (showMenuChildIndex === index && isChild && pindex == parentOfChild)
                      ? "active-color"
                      : ""
                  ].join(" ")}
                  key={el.title}
                  // onClick={e => showChildMenu(e, el, index, false, isChild)}
                  onClick={() => setShowTwoMenuIndex(!showTwoMenuIndex)}
                >
                  {el.title}

                  <span>
                    {
                      showTwoMenuIndex ? '-' : '+'
                    }</span>
                </div>


                {
                  showTwoMenuIndex ?

                    <div className={["item"].join(' ')}>
                      {
                        el.childrens.map(item => (

                          <div className={["item_chs", pathname == item.path ? 'active-color' : ''].join(' ')}

                            onClick={e => showChildMenu(e, item, 100, false, false)}
                          >{item.title}

                            {item.path == '/coinInsurance/pool' ? <img className="huo" src={HuoImg} /> : ''}
                          </div>
                        ))
                      }
                    </div> : ''
                }
              </div>
            )
          } else if (el.children) {
            return (
              <div className="item-box" key={"drawer_menu_" + index}>
                {/* {
                    showMenuIndex === index && !isChild ? <img className="menu-active" src={Right} alt="" /> : ''
                  } */}
                <div
                  className={[
                    "item",
                    showMenuIndex === index ? "active-color" : "",
                    "block-menu"
                  ].join(" ")}
                  onClick={e => showChildMenu(e, el, index, true, false)}
                >
                  {el.icon ? (
                    <img
                      className="icon"
                      src={showMenuIndex === index ? el.iconActive : el.icon}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  {el.title}
                  {showMenuIndex === index ? (
                    <img className="arrow" src={Up} alt="" />
                  ) : (
                    <img className="arrow" src={Down} alt="" />
                  )}
                </div>
                <DrawerMenu
                  data={el.children}
                  index={index}
                  isChild={true}
                  key={"drawer_menu_" + index}
                />
              </div>
            );
          } else {
            return (
              <div className="item-box" key={"menu_" + index}>
                {showMenuIndex === index && !isChild ? (
                  <img className="menu-active" src={Right} alt="" />
                ) : (
                  ""
                )}
                <div
                  className={[
                    "item",
                    (showMenuIndex === index && !isChild) ||
                      (showMenuChildIndex === index && isChild && pindex == parentOfChild)
                      ? "active-color"
                      : ""
                  ].join(" ")}
                  key={el.title}
                  onClick={e => showChildMenu(e, el, index, false, isChild)}
                >
                  {el.icon ? (
                    <img
                      className="icon"
                      src={showMenuIndex === index ? el.iconActive : el.icon}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  {el.title}
                  {el.path == '/coinInsurancelist' ? <img className="huo" src={HuoImg} /> : ''}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const showDrawer = () => {
    if (open) {
      setOpen(false);
      return;
    }
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    creatSwiper()
  }, [])

  useEffect(() => {
    creatSwiper()
  }, [localStorage.getItem('i18nextLng')])

  const creatSwiper = () => {

    const swiper = new Swiper('.swiper-container2', {
      slidesPerView: 1, // 可以放下几个元素
      loop: true,
      autoplay: {
        delay: 2000,
      },

      // effect: 'fade'
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true
      // },
    })
  }


  return isMobile ? (
    <>
      <div className="nav_box">
        {
          account ? <AccountDialog visible={accountVisible} setVisible={setAccountVisible} setAccount={setAccount}></AccountDialog> : ''
        }
        <LinkDialog visible={linkVisible} setVisible={setLinkVisible} setAccount={setAccount}></LinkDialog>
        <div className="flex nav">
          <div className="logo" onClick={() => window.location = config.siteUrl}>
            <img src={LogoNew} alt="" />
          </div>
          <div className="flex">
            <div className="flex nav_title_container">
              <div className="nav_title" onClick={() => history.push("/home")}>
                {t('home_title')}
              </div>
              <div
                className="nav_title"
                onClick={() => history.push("/insurance")}
              >
                {t('home_action_buy')}
              </div>
              <div className="nav_title" onClick={() => history.push("/bond")}>
                {t('home_action_earn_stake')}
              </div>
            </div>
            <div className="icon_box" onClick={showDrawer}>
              <img src={BurgerBlue} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Drawer
        className="nav-drawer"
        width="100%"
        placement="right"
        maskClosable={false}
        onClose={onClose}
        visible={open}
      >
        <div className="flex nav_drawer_quick_menu">
          <div className="logo nav-drawer">
            <img src={LogoNew} alt="" />
          </div>
          <div className="flex nav_drawer_quick_menu">
            {/* <div className="nav_drawer_quick_menu_item">English</div> */}
            <H5SelectLang />
            <div className="nav_drawer_quick_menu_item nav_drawer_wallet"
              onClick={() => {
                account ? setAccountVisible(true) :
                  setLinkVisible(true)
              }}
            >
              <img src={WalletBlue} alt="" />
            </div>
          </div>
        </div>
        <div className="drawer-list">
          <DrawerMenu data={newList} />
        </div>
        <div className="concat">
          {/* <div className="docs">
            <span>{t("text60")}</span>
            <div className="line" />
            <span>{t("text61")}</span>
          </div> */}
          <div className="img-box">
            <a href={i18n.language.toUpperCase() == 'ZH' ? config.documentUrlZH : config.documentUrl} target="_blank">
              <img src={GitBook} alt="" />
            </a>
            <a href={config.twitterUrl} target="_blank">
              <img src={Twitter} alt="" />
            </a>
            <a href={config.mediumUrl} target="_blank">
              <img src={Medium} alt="" />
            </a>
            <a href={config.discordUrl} target="_blank">
              <img src={Discord} alt="" />
            </a>
            <a href={config.telegramUrl} target="_blank">
              <img src={Telegram} alt="" />
            </a>
          </div>
          <div>© 2022 Marquee. All Rights Reserved. </div>
        </div>
      </Drawer>
    </>
  ) : (
    <div className="nav_box">
      {
        account ? <AccountDialog visible={accountVisible} setVisible={setAccountVisible} setAccount={setAccount}></AccountDialog> : ''
      }
      <LinkDialog visible={linkVisible} setVisible={setLinkVisible} setAccount={setAccount}></LinkDialog>
      <div className="nav"
        style={{
          height: `calc(100vh - ${props.isShowAirDrop ? 104 : 34}px)`,
          // marginTop: props.isShowAirDrop ? 70 : 0 
        }}
      >
        <div className="logo" onClick={() => window.location = config.siteUrl}>
          <img src={LogoNew} alt="" />
        </div>
        <div className="wallet-box">
          {account ? (
            <div className="no-wallet" onClick={() => setAccountVisible(true)}><img src={WalletBlue} alt="" />{shortenAddress(account, 6)}</div>
          ) : (
            <div className="no-wallet" onClick={() => setLinkVisible(true)}>{t('common.连接钱包')}</div>
          )}
        </div>
        {/* <div className="ads-box" onClick={() => {
          sendAnalytic("promotion_enter", { account: account.split("0x")[1], action: 'Side Banner' })
          history.push('/cryptoActivity');
        }}>

          <div className="text1">Marquee<img className="huo2" src={HuoImg} /><img className="huo2" src={HuoImg} /><img className="huo2" src={HuoImg} /></div>
          <div className="swiper_box">
            <div className="swiper_box2">
              <div className="swiper-container swiper-container2">
                <div className="swiper-wrapper">
                  <div className="home_banner swiper-slide">
                    <div className="text2">{t('new.product')}</div>
                  </div>
                  <div className="home_banner swiper-slide">
                    <div className="text2">{t('new.volatilityinsuance')}</div>
                  </div>
                  <div className="home_banner swiper-slide">
                    <div className="text2">{t('new.rewardworth1m')}</div>
                  </div>
                  <div className="home_banner swiper-slide">
                    <div className="text2">{t('new.covereverything')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="list scrollinvisible">
          <Menu data={newList} />
        </div>
        <div className="concat">
          {/* <div className="docs">
            <span>{t("text60")}</span>
            <div className="line" />
            <span>{t("text61")}</span>
          </div> */}
          <div className="img-box">
            <a href={i18n.language.toUpperCase() == 'ZH' ? config.documentUrlZH : config.documentUrl} target="_blank">
              <img src={GitBook} alt="" />
            </a>
            <a href={config.twitterUrl} target="_blank">
              <img src={Twitter} alt="" />
            </a>
            <a href={config.mediumUrl} target="_blank">
              <img src={Medium} alt="" />
            </a>
            <a href={config.discordUrl} target="_blank">
              <img src={Discord} alt="" />
            </a>
            <a href={config.telegramUrl} target="_blank">
              <img src={Telegram} alt="" />
            </a>
          </div>
          <div>© 2022 Marquee. All Rights Reserved. </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
