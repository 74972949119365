import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import MarqueeInscriptionABI from "./abi/marqueeInscription.json";
import config from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class MarqueeInscriptionService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.marqueeInscriptionContract = new this.web3.eth.Contract(
        MarqueeInscriptionABI,
        config.marqueeInscription
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  async inscribe(text) {
    const account = await this.getCurrentAccount();
    try {
      return await this.marqueeInscriptionContract.methods
        .inscribe(text)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }


}

export default MarqueeInscriptionService;
