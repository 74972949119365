// BSC 测试
export default {
  dappUrl: "https://test.marquee.fi/",
  siteUrl: "https://marquee.fi/",
  baseUrl: "https://testapi.marquee.fi",
  documentUrl: "https://docs.marquee.fi/",
  documentUrlZH: "https://docs.marquee.fi/",
  inviteUrl: "https://test.marquee.fi/coinInsurancelist",

  // 安全险
  ethRpc: "https://sepolia-rollup.arbitrum.io/rpc",
  posRootERC20: "0x2f21D865a4efE10994d021F9FF1889B5E8A1EFE2", // USDT
  CSInsurance: "0x697856b4626Cf6a1Fc5fCe85DaE5F16945c83038",
  RewardPool: "0xD2a096394bf87b30E3eFF84E4A2F2D17bF770A81",
  VoteDAO: "0xfea6EE1db4759517B0C8d50b771b364F9DaC91e5",
  IPSTToken: '0xcb2A331b63Dd8Be1f513c8A814eEcc2a0765F077', // CPST
  pendant: "0xD45cC28Af8B906f52698F0b3869798CD883B4352",

  networkId: 421614, //BSC测试链
  blockExplorerUrls: "https://sepolia.arbiscan.io/", // 网站地址
  wsUrl: "wss://testapi.marquee.fi",
  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币地址
  mainChainSymbol: "ETH", // 主链币名称
  blockTime: 15, // BSC出一个块的时间(秒)

  ismAddr: "0xec7a9d96DA1E1ac2Eca3510b87C65Df2eb71f248",

  usdtToken: {
    address: "0x2f21D865a4efE10994d021F9FF1889B5E8A1EFE2",
    symbol: "USDT",
    decimals: 6
  },
  v2LpToken: {  // 废弃
    address: "0x143bBd7c858D1ee92C94dF9c1cE8c884767DdAe8",
    symbol: "v2LP",
    decimals: 18
  },

  // 币价险
  lpToken: {
    address: "0x359Cec496c2DB24E39a30Be4C2B29459FA0D88c9",
    symbol: "IPST",
    decimals: 18
  },
  erc721ContractAddr: "0x8495c7dc0deA9a0e392a9Bfd0a14df9e8E8a829b",
  rewardPoolContractAddr: "0x12EAD7C4498cE6d5ECf94F1F3f19692BfEB7011f",
  insuranceContractAddr: "0xec7a9d96DA1E1ac2Eca3510b87C65Df2eb71f248",
  InsurePay: "0x3978b3dC7F5e1d1eb58Cf93fC12d3bfDF1658473",
  masterChefContractAddr: "0xF8EF514917a78b0A7E5E043983FFcEbe3BFcAa7d", // 废弃
  ismLockPoolContractAddr: "0x0BB8584F5Bc236533a6A4790802104222D26B9F4",// 废弃

  // 国库 债卷合约
  neiBondAddr: "0x3B7F9Ef9e174bc2A36f8B6f030106ceCF9Fa26c5", // 债券合约 NEDTokenBond
  neiVaultAddr: "0x147A483bE00e810C8351dedF544810A83274B97d", // 国库

  // 年金保险
  Auth: "0x72afbeDA2d08B46420F36A7016768700b1de1DB1",
  Factory: "0x92e8BdfBD1Bcfb7945Da536a4bA727D93eA805b8",
  Algorithm: "0x473E30C80D2049Adb98F58D143dd6769ABe867FA",
  AnnuityData: "0xB76C1149A6c718CC856ECccE0FeADAB5e14206b9",
  RouterV1: "0x24D168001ffc1D690a42d05663c9aE670A6C5F26",
  ErrorContract: "0x2086Ed92cB6F72AB5CA0628B2d3F65ec96F907a6",


  nedToken: {
    address: "0x571d492B7880A41776B17D01A59F59Fb42b1C3f0",
    symbol: "Marq",
    decimals: 9
  },
  sNedToken: {
    address: "0x0cC6e62A4d9780FD7C8B3080F2D9DBBF8f845c0F",
    symbol: "sMarq",
    decimals: 9
  },
  gNedToken: {
    address: "0x47a239d30652B47226e7b241Bc1A78Ea09681751",
    symbol: "gMarq",
    decimals: 18
  },
  usdtToken: {
    address: "0x2f21D865a4efE10994d021F9FF1889B5E8A1EFE2",
    symbol: "USDT",
    decimals: 6
  },

  inviteMerkleDistributor: "0x837fcabe13cB4da122048C9fcD15BAbc1BF25FD4",

  oldMarq: "0xb2CCe9bDe4177B5A39a6ade5f3881Cb65D447D93",
  newMarq: "0x7A23af3A4B5fE509cCE22D0Ba02a983ea3D7f5D0",
  Trading: "0xbb60d418AB2C925C6c8A09a40812670CdD3bd131",
  MarqSwap: "0x33E2D81A7d2AD4aB41B5E422D548e5c40Ad20dB3",

  "twitterUrl": "https://twitter.com/Marquee_fi",
  "mediumUrl": "https://medium.com/@MarqueeOfficial",
  "discordUrl": "https://discord.com/invite/cp7akF4bg9",
  "telegramUrl": "https://t.me/MarqueeGroup",
  "gtm": "G-K9H76Q07BR",
};

