import Loadable from "react-loadable";
import {Spin} from 'antd'

export default loader => {
  return Loadable({
    loader,
    loading() {
      // return "";
      return (
        <div
          className="flex flex-column"
          style={{
            width: "100%",
            height: "80vh",
            fontSize: "16px",
            color: "#005be6",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div className="loading">
            <Spin size="large"></Spin>
          </div>
          <span>{localStorage.getItem('i18nextLng') === 'ZH' ? '加载中 请等待...' : 'Please, wait...'}</span>
        </div>
      );
    }
  });
};
