import React from "react";
import { notification } from "antd";

import "./index.scss";
import SuccessTipIcon from "./images/success.png";
import ErrorTipIcon from "./images/error.png";
import InfoTipIcon from "./images/info.png";
import LoadingIcon from "./images/loading-icon.png";
import VoteIcon from "./images/vote.png"
import CloseIcon from "./images/close.png";
// type === 'loading' 提示框不会自动关闭 其余类型4秒后自动关闭

export const showTip = (
  key = "none",
  type = "loading",
  title = "",
  message
) => {
  notification.open({
    key,
    btn: "",
    closeIcon: <img src={CloseIcon} style={{ width: 14, height: 14 }} />,
    icon: "",
    className: "message-tip",
    close: "",
    placement: "topRight",
    description: (
      <div className="message-tip__content">
        {type === "success" ? (
          <img src={SuccessTipIcon} alt="success" className="success-img" />
        ) : type === "loading" ? (
          <img src={LoadingIcon} alt="loading" className="loading-img" />
        ) : type === "info" ? (
          <img src={InfoTipIcon} alt="swap" className="swap-img" />
        ) : type === "vote" ? (
          <img src={VoteIcon} alt="swap" className="swap-img" />
        ) : (
          <img src={ErrorTipIcon} alt="error" className="error-img" />
        )}
        <div className="info">
          <div className="info-title">{title}</div>
          {
            message ?
              <div className="info-message">{message}</div> : ''
          }
        </div>
      </div>
    ),
    duration: type !== "loading" ? 2 : null
  });
};

export const closeTip = key => {
  notification.close(key);
};
