import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import NEIVaultABI from "./abi/NEIVaultABI";
import { sendAnalytic } from "../utils/analytic"
import { nToBN, bNToN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class NeiVaultService extends Erc20Service {
  web3;
  neiVaultContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.neiVaultContract = new this.web3.eth.Contract(
        NEIVaultABI,
        networkConfig.neiVaultAddr
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  // 根据地址，查询warmup时期信息
  // 获取我的质押 NED
  async getWarmupInfo() {
    const account = await this.getCurrentAccount();
    try {
      const warmupInfo = await this.neiVaultContract.methods
        .warmupInfo(account)
        .call();
      return {
        deposit: warmupInfo.deposit, // 抵押的NEI数量
        gons: warmupInfo.deposit, // 下注的转换为gons的值
        expiry: warmupInfo.expiry, // warmup结束值
        lock: warmupInfo.lock // 是否锁定， 防止索赔的恶意延迟
      };
    } catch (err) {
      console.log(err);
      return;
    }
  }

  // 查询纪元信息
  async getEpoch() {
    try {
      const epoch = await this.neiVaultContract.methods.epoch().call();
      return {
        length: epoch.period, // 时间长度， 8小时
        number: epoch.number, // 次数
        end: epoch.end, // 可定基的时间
        // distribute: epoch.distribute // 分发的金额
        nextBlock: epoch.nextBlock, // 下次变基的时间
        distribute: epoch.distribute // 下次分发的金额
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }

  // 查询纪元信息
  async getCurrentBlockNum() {
    try {
      const currentBlock = await this.neiVaultContract.methods
        .getBlockNum()
        .call();
      return currentBlock;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  // 抵押NEI, 返回给受益地址的金额
  async depositNEI(neiAmount, rebasing = true, claim = true) {
    const account = await this.getCurrentAccount();
    try {
      const amount = nToBN(neiAmount, networkConfig.nedToken.decimals); // 抵押的NEI金额
      const recipient = account; // 受益地址

      const sNEIAmount = await this.neiVaultContract.methods
        .stake(recipient, amount, rebasing, claim)
        .send({ from: account });
      sendAnalytic("stake", { account: account.split("0x")[1], marq: neiAmount, smarq: neiAmount })
      return sNEIAmount;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // 用sNEI（转账）或者gNEI（销毁）赎回NEI
  // 取回NEI
  async withdrawNEI(sNEIAmount) {
    const account = await this.getCurrentAccount();
    try {
      const amount = nToBN(sNEIAmount, networkConfig.sNedToken.decimals); // 取回的NEI金额
      const trigger = false; // 是否触发变基
      const rebasing = true; // 是否重新定基

      const NEIAmount = await this.neiVaultContract.methods
        .unstake(account, amount, trigger, rebasing)
        .send({ from: account });
      sendAnalytic("unstake", { account: account.split("0x")[1], marq: sNEIAmount, smarq: sNEIAmount })
      return NEIAmount;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  // 查询当前时间戳
  async getCurrTime() {
    try {
      const currTime = await this.neiVaultContract.methods.getCurrTime().call();
      return currTime;
    } catch (err) {
      console.log(err);
      return;
    }
  }
}

export default NeiVaultService;
