import React, { useState, useEffect, useCallback } from "react";
import Down from "../../assets/images/select-arrow.png";
import i18next from "i18next";
import { useMedia } from "react-use";
import "./index.scss";


const LangList = [
  {
    lang: "English",
    key: "EN"
  },
  {
    lang: "中文",
    key: "ZH"
  },
  // {
  //   lang: "한국어",
  //   key: "KO"
  // },
  /*{
    lang: "русский",
    key: "RU"
  },
  {
    lang: "Tiếng Việt",
    key: "TH"
  },
  {
    lang: "ไทย",
    key: "VI"
  },
  {
    lang: "日本語",
    key: "JA"
  },
  {
    lang: "Italiano",
    key: "IT"
  }*/
];


const SelectLang = props => {
  const { footerWallet } = props;
  const [selectLang, setSelectLang] = useState(false);
  const [langText, setLangText] = useState("");
  const [selectedLang, setSelectedLang] = useState("")
  const below960 = useMedia("(max-width: 960px)");

  const setLang = () => {
    const language = localStorage.getItem("i18nextLng");
    setSelectedLang(language)
    if (language === "EN") {
      setLangText(below960 ? "English" : "English");
    }
    if (language === "ZH" || language === "zh-CN" || language === "zh") {
      setLangText(below960 ? "中文" : "中文");
      // document.title = "MiniDEX-Layer2 基于零知识证明技术的交易解决方案"
    }
    if (language === "RU") {
      setLangText(below960 ? "RU" : "русский");
    }
    if (language === "KO") {
      setLangText(below960 ? "KO" : "한국어");
    }
    if (language === "TH") {
      setLangText(below960 ? "TH" : "Tiếng Việt");
    }
    if (language === "VI") {
      setLangText(below960 ? "VI" : "ไทย");
    }
    if (language === "JA") {
      setLangText(below960 ? "JA" : "日本語");
    }
    if (language === "IT") {
      setLangText(below960 ? "IT" : "Italiano");
    }
  };

  useEffect(() => {
    setLang();
  }, []);

  const toogleLang = useCallback((e, lang) => {
    /**修改语言 */
    e.stopPropagation();
    i18next.changeLanguage(lang, () => { });
    setSelectLang(false);
    setLang();
  }, []);
  const close = e => {
    e.stopPropagation();
    setSelectLang(false);
  };
  return (
    <div
      className="h5_select_lang"
      onClick={() => setSelectLang(true)}
    >
      <div className="lang_text">
        <span>{langText}</span>
        <img src={Down} alt="" className={selectLang ? "select_lang_icon active" : "select_lang_icon"} />
      </div>
      {selectLang ? (
        <>
          <div className="select_lang_mask" onClick={e => close(e)} />
          <div className={["select_lang_menu", "toggle"].join(" ")}>
            {LangList.map(item => (
              <div
                className={["select_lang_menu_item", selectedLang == item.key ?"selected_lang_menu_item":""].join(" ")}
                key={item.key}
                onClick={e => toogleLang(e, item.key)}
              >
                {item.lang}
              </div>
            ))}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectLang;
