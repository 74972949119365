import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import ISMLockPoolABI from "./abi/ISMLockPoolABI";

import { nToBN, bNToN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class IsmLockPoolService extends Erc20Service {
  web3;
  ismLockPoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.ismLockPoolContract = new this.web3.eth.Contract(
        ISMLockPoolABI,
        networkConfig.ismLockPoolContractAddr
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  // 查询用户地址解锁金额
  async unlockedOf() {
    const account = await this.getCurrentAccount();
    try {
      return await this.ismLockPoolContract.methods.unlockedOf(account).call();
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询用户地址解锁金额
  async lockedOf() {
    const account = await this.getCurrentAccount();
    try {
      const amount = await this.ismLockPoolContract.methods
        .lockedOf(account)
        .call();
      console.log("lockedOf:", amount);
      return bNToN(amount, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // // 抵押指定代币对应金额，同时结算之前有多少解锁的ism给用户，更新新的抵押和解锁数据,转账给合约
  // async stake(token, amount) {
  //   const account = await this.getCurrentAccount();

  //   try {
  //     return await this.ismLockPoolContract.methods
  //       .stake(token, amount)
  //       .send({ from: account });
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }
  // }

  // // 赎回指定币,指定金额,同时结算之前应有多少解锁的ism给用户，更新新的抵押和解锁数据，转账给用户
  // async unstake(token, amount) {
  //   const account = await this.getCurrentAccount();

  //   try {
  //     return await this.ismLockPoolContract.methods
  //       .unstake(token, amount)
  //       .send({ from: account });
  //   } catch (e) {
  //     console.log(e);
  //     return;
  //   }
  // }

  // 已存入LP数量
  async getLpAmount() {
    const account = await this.getCurrentAccount();

    try {
      // const tokenAddress = networkConfig.v2LpToken.address;
      const lpAmount = await this.ismLockPoolContract.methods
        .getStaked(account)
        .call();
      console.log("lpAmount:", lpAmount);
      return bNToN(lpAmount, networkConfig.v2LpToken.decimals);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询用户当前抵交易对的ism解锁速度
  async getUnlockSpeed() {
    const account = await this.getCurrentAccount();
    try {
      const unlockSpeed = await this.ismLockPoolContract.methods
        .getUnlockSpeed(account)
        .call();

      console.log("unlockSpeed:", unlockSpeed);
      // return bNToN(unlockSpeed / 3, 18);
      return bNToN(
        parseInt(new BigNumber(unlockSpeed).div(networkConfig.blockTime)),
        18
      );
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询分母因子
  async getDenominator() {
    try {
      const denominator = await this.ismLockPoolContract.methods
        .FACTOR_DENOMINATOR()
        .call();
      console.log("denominator:", denominator);
      return denominator;
      // return bNToN(denominator, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 已解锁的ISM
  // 查询当前用户当前抵押币获取的ism已解锁的金额
  async claimableUnlocked() {
    const account = await this.getCurrentAccount();
    try {
      const ableUnlockedAmount = await this.ismLockPoolContract.methods
        .claimableUnlocked()
        .call({ from: account });
      console.log("ableUnlockedAmount:", ableUnlockedAmount);
      return bNToN(ableUnlockedAmount, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 收获解锁奖励
  async claimUnlocked() {
    const account = await this.getCurrentAccount();

    try {
      return await this.ismLockPoolContract.methods
        .claimUnlocked()
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 流动性累计
  // 查询用户抵押lp累计挖矿金额
  async userMint() {
    try {
      return await this.ismLockPoolContract.methods.userMint().call();
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 流动性累计
  // 查询用户当前lp挖矿数量,默认传0
  async pendingIsm() {
    const account = await this.getCurrentAccount();

    try {
      const pid = 0;
      return await this.ismLockPoolContract.methods
        .pendingIsm(pid, account)
        .call();
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // ===待解锁池金额;
  // 已解锁的ISM
  // 查询用户当前动态解锁金额
  async pendingUnlockAmount() {
    const account = await this.getCurrentAccount();

    try {
      const amount = await this.ismLockPoolContract.methods
        .pendingUnlockAmount(account)
        .call();
      return bNToN(amount, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 存入lp方法
  // 抵押指定代币对应金额，同时结算之前有多少解锁的ism给用户，更新新的抵押和解锁数据,转账给合约
  async stake(amount) {
    const account = await this.getCurrentAccount();

    try {
      // const tokenAddress = networkConfig.v2LpToken.address;
      const decimals = await this.getTokenDecimals(
        networkConfig.v2LpToken.address
      );
      amount = nToBN(amount, decimals);
      console.log("amount:", amount);

      return await this.ismLockPoolContract.methods
        .stake(amount)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 取回v2lp方法
  // 赎回指定币，指定金额,同时结算之前应有多少解锁的ism给用户，更新新的抵押和解锁数据，转账给用户
  async unstake(amount) {
    const account = await this.getCurrentAccount();

    try {
      // const tokenAddress = networkConfig.v2LpToken.address;
      const decimals = await this.getTokenDecimals(
        networkConfig.v2LpToken.address
      );
      amount = nToBN(amount, decimals);
      console.log("amount:", amount);

      return await this.ismLockPoolContract.methods
        .unstake(amount)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询解锁因子
  async getUnlockFactor() {
    try {
      // const tokenAddress = networkConfig.v2LpToken.address;
      const unlockFactor = await this.ismLockPoolContract.methods
        .getUnlockFactor()
        .call();

      // return bNToN(unlockFactor, networkConfig.v2LpToken.decimals);
      return unlockFactor;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 解锁区块间隔
  async getUnlockBlockGap() {
    try {
      // const tokenAddress = networkConfig.v2LpToken.address;
      const unlockBlockGap = await this.ismLockPoolContract.methods
        .getUnlockBlockGap()
        .call();

      return unlockBlockGap;
    } catch (e) {
      console.log(e);
      return;
    }
  }
}

export default IsmLockPoolService;
