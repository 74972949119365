import axios from "../axios";

// 获取市场合约列表
export const getHomeData = (params) => {
  return axios.get("/client/contractinsurance/market", params);
}

// 首页我的保单
export const getMyInsurance = (params, opt) => {
  return axios.get("/client/get_my_insurance", params, opt);
}

// 获取市场合约列表
export const getHomeTotalData = (params) => {
  return axios.get("/client/statisticsdata", params);
}

// 获取市场合约列表
export const getPersonnelPoolReward = (params) => {
  return axios.get("/client/get_reward_pool_net_amount", params);
}

// 获取市场筛选条件
export const getHomeFilterData = () => {
  return axios.get("/client/contractinsurance/market/query");
}
// 获取保单列表
export const getInsurance = params => {
  return axios.get("/client/contractinsurance/policy", params);
}
// 获取理赔列表
export const getClaim = (params, opt) => {
  return axios.get("/client/contractinsurance/claims", params, opt);
}
// 购买时获取倍数和理赔金额
export const getAmount = params => {
  return axios.get("/client/contractinsurance/buy", params);
}
// 申请理赔
export const claim = params => {
  return axios.post("/client/contractinsurance/claims", params);
}
// 申诉
export const appeal = params => {
  return axios.post("/client/contractinsurance/claimssecond", params);
}
// 详情
export const detail = params => {
  return axios.get("/client/contractinsurance/detail", params);
}
// 详情
export const claimDetail = params => {
  return axios.get("/client/contractinsurance/claims/detail", params);
}
// 历史投票详情
export const voteHistory = params => {
  return axios.get("/client/contractinsurance/votehistory", params);
}
// 获取资金池人数等信息
export const getPeople = () => {
  return axios.get("/client/contractinsurance/lp");
}
// 获取领取索赔参数
export const getClaimParams = params => {
  return axios.get("/client/contractinsurance/claims/receive", params);
}

// 获取折扣购买NEI列表数据
export const getBuyNEIList = async (data) => {
  // return axios.get("/client/nei/buy", data);
  return axios.get("/client/nei/buy_terms", data);
}

// 获取我的折扣购买NEI列表数据
export const getMyBuyNEIList = async (data) => {
  // return axios.get("/client/nei/buy", data);
  return axios.get("/client/nei/record", data);
}
// 获取nei 购买签名数据
export const getBuyNEISig = async (data) => {
  return axios.post("/client/nei/buy", data);
}

// 获取索赔签名
export const getClaimSig = async (data) => {
  return axios.get("/client/contractinsurance/get_apply_for_sign", data);
}
// 获取neD 价格
export const getNedPrice = async (coinId) => {
  return axios.get("/client/nei/cointonei", coinId);
}


// 获取nei信息详情
export const getNEI = async (data) => {
  return axios.get("/client/nei", data);
}

// 获取stake信息详情
export const getStake = async (data) => {
  return axios.get("/client/stake", data);
}


// 获取金库信息详情
export const getAsset = async (data) => {
  return axios.get("/client/asset", data);
}


// 获取我的保单列表数据
export const getPolicyList = async (data) => {
  return axios.get("/client/insurance/policy", data);
}

// 设置保单状态为索赔中
export const setPolicyStatus = async (data) => {
  return axios.post("/client/insurance/settlement", data);
}


// 获取结算签名
export const getPolicySig = async (data) => {
  return axios.get("/client/insurance/settlement", data);
}


// 获取保单总价值
export const getTotalAmount = async (data) => {
  return axios.get("/client/insurance/totalamount", data);
}


// 获取我的保单列表 所有币种数据
export const getMinePolicyOptionCoin = async (
  data = {
    address: "" // 钱包地址
  }
) => {
  return axios.get("/client/coin", data);
}

// 存入USDT
export const depositUSDT = async (data) => {
  return axios.post("/client/insurance/addAssets", data);
}

// 取回USDT
export const withdrawUSDT = async (data) => {
  return axios.post("/client/insurance/claimAssets", data);
}

// 奖励释放
export const getTradingContestUnlock = async (data) => {
  return axios.get("/client/trading_contest/unlock", data);
}
// LP价格
export const getLpPrices = async (data) => {
  return axios.get("/client/insurance/lp", data);
}

// 获取汇总数据
export const getDataSummarys = async (data) => {
  return await axios.get("/client/indexdata", data);
}

// 获取保单价格及赔付倍数
export const getInsuranceAmounts = async (
  data = {
    coin_id: 1, // 币种ID
    trigger_price: 100, // 变动价
    insurance_type: 1, // 1暴跌; 2踏空
    insurance_day: 30, // 保险时间
    insurance_amount: 2000 // 赔付金额
  }
) => {
  data.trigger_price = data.trigger_price * 100000000
  return axios.get("/client/insurance/amount", data);
}


// 获取保险市场列表数据
export const getMarketLists = async (data) => {
  return axios.get("/client/insurance/market", data);
}
// 获取奖励分红信息
export const getReward = params => {
  return axios.get("/client/contractinsurance/reward", params);
}
// 获取分红提现签名信息
export const getRewardSign = params => {
  return axios.get("/client/contractinsurance/reward/contract", params);
}
// 获取奖励信息
export const getAwardInfo = params => {
  return axios.get("/client/contractinsurance/rewardpool", params);
}

export const getV2LpPairs = async (data) => {
  return axios.get("/client/lp", data);
}
// 获取NED换USDT的比率
export const getToUSDT = async (data) => {
  return axios.get("/client/nedtousdt", data);
}

export const getTestUSDT = async (data) => {
  return axios.get("/client/getTestUSDT", data);
}

export const getTestMarq = async (data) => {
  return axios.get("/client/marq_faucet", data);
}

// 获取空投排行榜
export const getAirDropRank = async (data) => {
  return axios.get("/client/user_experience_activity/get_rank", data);
}
// 获取空投详细信息
export const getAirDropDetail = async (data) => {
  return axios.get("/client/user_experience_activity/get_user_detail_now", data);
}

// 创建反馈
export const createApplicationFeedback = async (params) => {
  return axios.post("/client/user_experience_activity/feedback", params);
}

// 获取banner
export const getBanner = async (data) => {
  return axios.get("/client/show_banner", data);
}

// register ip
export const setIp = async (data) => {
  return axios.post("/client/set_user_ip", data);
}

// 邀请 
export const createInviteUserRelationship = async (data) => {
  // create_type = 1, 获得invite code
  return axios.post("/client/invite_return/create_user_relationship", data);
}

// 获取用户返佣
export const getInviteReturnUsers = async (data) => {
  return axios.get("/client/invite_return/get_user_records", data);
}

// 邀请返佣列表
export const getInviteReturnRankList = async (data) => {
  return axios.get('/client/invite_return/get_rank_list', data)
}


// 年金配套列表
export const getAnnualityInsures = async (data) => {
  return axios.get("/client/annuality/insures", data);
}
// 年金我的配套列表
export const getAnnualityMyInsures = async (data) => {
  return axios.get("/client/annuality/my_insures", data);
}
// 年金我的历史
export const getMyInsuresRecord = async (data) => {
  return axios.get("/client/annuality/my_insures_record", data);
}
// 价格保险保底购买列表
export const getTradingMyInsurance = async (data) => {
  return axios.get("/client/trading_contest/my_insurance", data);
}
// 排行榜列表
export const getTradingRank = async (data) => {
  return axios.get("/client/trading_contest/rank", data);
}
// 奖励列表
export const getTradingReward = async (data) => {
  return axios.get("/client/trading_contest/reward", data);
}
// 铭文列表
export const getPosyRank = async (data) => {
  return axios.get("/client/posy/rank", data);
}

// IDO
export const getIDO = async (data) => {
  return axios.postIdo('https://api.fvlaunch.io/api/launchpad/detail', data);
}

// IDO general
export const getIDOGeneral = async () => {
  return axios.getIdo('https://s3.ap-southeast-1.amazonaws.com/marquee.fi/ido/ido.json');
}
// IDO
export const getIDO2 = async (id) => {
  return axios.getIdo(`https://dash.zenixlauncher.pro/api/pools_show/${id}`, {});
}

// 当前期
export const getMiningCurrent = async (data) => {
  return axios.get("/client/activity/trade_mining/current", data);
}
// 记录
export const getMiningRecord = async (data) => {
  return axios.get("/client/activity/trade_mining/record", data);
}