import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import ZENIXABI from "./abi/ZENIXABI.json";
import config from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class ZenixService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.zenixContract = new this.web3.eth.Contract(
        ZENIXABI,
        config.idozenix
      );
    } catch (e) {
      console.log(e.message);
    }
  }
  // 已经售卖
  async alreadyRaised() {
    try {
      const res = await this.zenixContract.methods
        .alreadyRaised()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }
  // 最大售卖
  async hardCap() {
    try {
      const res = await this.zenixContract.methods
        .hardCap()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }
}

export default ZenixService;
