import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import RewardABI from "./abi/Reward.json";
import config from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class RewardService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.rewardContract = new this.web3.eth.Contract(
        RewardABI,
        config.Reward
      );
      
    } catch (e) {
      console.log(e.message);
    }
  }

  async getRewardUserInfo() {
    try {
      const account = await this.getCurrentAccount();
      return await this.rewardContract.methods
        .getRewardUserInfo(account)
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  async getRewardUserInfo() {
    try {
      const account = await this.getCurrentAccount();
      return await this.rewardContract.methods
        .getRewardUserInfo(account)
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async claim(amount) {
    const account = await this.getCurrentAccount();
    try {
      return await this.rewardContract.methods
        .claim(amount)
        .send({ from: account });
    } catch (e) {
      console.log('%c e', 'color: #00A0E9; font-size: 26px; font-weight: blod;', e);
      return e;
    }
  }

}

export default RewardService;
