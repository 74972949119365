import { useMemo } from "react";
import { useMedia } from "react-use";

const useDevice = () => {
  const below960 = useMedia("(max-width: 960px)");

  return useMemo(() => {
    return {
      isMobile: below960,
      isPc: !below960
    };
  }, [below960]);
};

export default useDevice;
