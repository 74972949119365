// BSC 测试 magnify

export default {
  dappUrl: "http://47.241.66.95/marquee-dapp-dev-magnify/",
  siteUrl: "https://marquee.fi/",
  // baseUrl: "http://161.117.249.180:9005",
  baseUrl: "https://ism-client-bsc-magnify.nodedao.io",
  documentUrl: "https://docs.marquee.fi/",
  documentUrlZH: "https://docs.marquee.fi/",
  inviteUrl: "https://testinvite.marquee.fi",

  // 安全险
  ethRpc: "https://bsc-testnet.public.blastapi.io",
  posRootERC20: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // USDT
  CSInsurance: "0xB2Dd0aa4Bc5345C627D68897165043452F304439",
  RewardPool: "0x7EEDd24F7e21668B10E4267e3BF6ad5A1e9CFa06",
  VoteDAO: "0x6ae62241f3Ca218176Bd25E22bA4b1e275098137",
  IPSTToken: '0xecFB593Dcd10fD1c64b277d250f70f5B87a5A6Fd', // CPST

  networkId: 97, // 测试
  blockExplorerUrls: "https://testnet.bscscan.com", // 网站地址
  wsUrl: "wss://ism-client-bsc-magnify.nodedao.io",
  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币地址
  mainChainSymbol: "BSC Testnet", // 主链币名称
  blockTime: 3, // BSC出一个块的时间(秒)

  ismAddr: "0xd40aC65B1771dA5cFC5fE43dA2854d2ff659A562",

  usdtToken: {
    address: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244",
    symbol: "USDT",
    decimals: 18
  },
  v2LpToken: {
    address: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244",
    symbol: "v2LP",
    decimals: 18
  },

  // 币价险
  lpToken: {
    address: "0xe2F2B6245Fec5F0bD3B34a17e8f899938cb711f2",
    symbol: "IPST",
    decimals: 18
  },
  erc721ContractAddr: "0x0fB0f0Fb315aE1121f66945925274724E1a01d87",
  rewardPoolContractAddr: "0x837fcabe13cB4da122048C9fcD15BAbc1BF25FD4",
  insuranceContractAddr: "0xde71a104b801723F08Bb80eE822322b4381Ba26e",
  masterChefContractAddr: "0xF8EF514917a78b0A7E5E043983FFcEbe3BFcAa7d", // sunset
  ismLockPoolContractAddr: "0x0BB8584F5Bc236533a6A4790802104222D26B9F4", // sunset

  // 国库 债卷合约
  neiBondAddr: "0xC46C2f9dcAA375899ceaE51440Da8edc8662dc24",//"0xb492A99B818Fe56A5B228c2f91C38535d056EF7e", // 债券合约
  neiVaultAddr: "0x5920EaFBf262E0783EB975Ed79CDB10f7979e223", // 国库

  nedToken: {
    address: "0xdd08741987c941aD7c802Cfcc2f709f39f6F9b78",
    symbol: "NED",
    decimals: 9
  },
  sNedToken: {
    address: "0x56Cf39DAAF33129A89d3891eb82698FFed63B064",
    symbol: "sNED",
    decimals: 9
  },
  gNedToken: {
    address: "0x2d5cDD5BEfFAfCC99d37Fc5268b06f73F34d56E0",
    symbol: "gNED",
    decimals: 18
  },
  usdtToken: {
    address: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244",
    symbol: "USDT",
    decimals: 18
  },

  inviteMerkleDistributor: "0xf54de1deebe8242dcdb64da6ab5616aa4990985f",

  "twitterUrl": "https://twitter.com/Marquee_fi",
  "mediumUrl": "https://medium.com/@MarqueeOfficial",
  "discordUrl": "https://discord.com/invite/cp7akF4bg9",
  "telegramUrl": "https://t.me/MarqueeGroup",
  "gtm": "G-K9H76Q07BR",
};