import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import IDOABI from "./abi/IDOABI.json";
import config from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class IdomaticService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);


      const maticurl = new Web3.providers.HttpProvider(config.ido.matic.rpc);
      const maticRPC = new Web3(maticurl);
      this.maticContract = new maticRPC.eth.Contract(
        IDOABI,
        config.ido.matic.address
      );

      const bscurl = new Web3.providers.HttpProvider(config.ido.bsc.rpc);
      const bscRPC = new Web3(bscurl);
      this.bscContract = new bscRPC.eth.Contract(
        IDOABI,
        config.ido.bsc.address
      );

      const arburl = new Web3.providers.HttpProvider(config.ido.arb.rpc);
      const arbRPC = new Web3(arburl);
      this.arbContract = new arbRPC.eth.Contract(
        IDOABI,
        config.ido.arb.address
      );


    } catch (e) {
      console.log(e.message);
    }
  }

  async chainRaised(chainId) {
    try {
      const res = await this.maticContract.methods
        .chainRaised(chainId)
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }

  async idoMaticPrice() {
    try {
      const res = await this.maticContract.methods
        .price()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }

  async idoMaticSold() {
    try {
      const res = await this.maticContract.methods
        .sold()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }

  async idoMaticSale() {
    try {
      const res = await this.maticContract.methods
        .sale()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }
  async idoBscPrice() {
    try {
      const res = await this.bscContract.methods
        .price()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }

  async idoBscSold() {
    try {
      const res = await this.bscContract.methods
        .sold()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }

  async idoBscSale() {
    try {
      const res = await this.bscContract.methods
        .sale()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }
  async idoArbPrice() {
    try {
      const res = await this.arbContract.methods
        .price()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }

  async idoArbSold() {
    try {
      const res = await this.arbContract.methods
        .sold()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }

  async idoArbSale() {
    try {
      const res = await this.arbContract.methods
        .sale()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }
  // 已经售卖
  async sold() {
    try {
      const p = await this.price()
      const res = await this.idomaticContract.methods
        .sold()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }
  // 最大售卖
  async sale() {
    try {
      const res = await this.idomaticContract.methods
        .sale()
        .call();
      return res;
    } catch (e) {
      return 0;
    }
  }
}

export default IdomaticService;
