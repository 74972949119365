import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import sNEIErc20ABI from "./abi/sNEIErc20ABI";

import { nToBN, bNToN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class sNEIErc20Service extends Erc20Service {
  web3;
  sNEIErc20Contract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.sNEIErc20Contract = new this.web3.eth.Contract(
        sNEIErc20ABI,
        networkConfig.sNedToken.address
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  // 预计收益
  async getUserSubProfit(amount) {

    const account = await this.getCurrentAccount();
    try {
      amount = nToBN(amount, networkConfig.nedToken.decimals);
      const res = await this.sNEIErc20Contract.methods
        .getUserSubProfit(account, amount)
        .call();
      const res1 = {
        after: bNToN(res[0], networkConfig.nedToken.decimals),
        before: bNToN(res[1], networkConfig.nedToken.decimals),
        compared: bNToN(res[2], networkConfig.nedToken.decimals)
      }
      return res1;
    } catch (err) {
      console.log(err);
      return;
    }
  }
  // 预计收益
  async getUserAddProfit(amount) {

    const account = await this.getCurrentAccount();
    try {
      amount = nToBN(amount, networkConfig.nedToken.decimals);
      const res = await this.sNEIErc20Contract.methods
        .getUserAddProfit(account, amount)
        .call();
      const res1 = {
        after: bNToN(res[0], networkConfig.nedToken.decimals),
        before: bNToN(res[1], networkConfig.nedToken.decimals),
        compared: bNToN(res[2], networkConfig.nedToken.decimals)
      }
      return res1;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  // 下次发放收益
  async getUserProfit() {
    const account = await this.getCurrentAccount();
    try {
      const res = await this.sNEIErc20Contract.methods
        .getUserProfit(account)
        .call();

      const res1 = bNToN(res, networkConfig.sNedToken.decimals);
      return res1;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  // 根据变基次数，查询sNEI前后的总供应量
  async epochTotalSupply(numbert) {
    try {

      let epochRate = 0;
      if (numbert === -1) {
        epochRate = 0;
      } else {
        const info = await this.sNEIErc20Contract.methods
          .epochTotalSupply(numbert)
          .call();
        // return {
        //   beforeTotalSupply: info.beforeTotalSupply, // 变基前的sNEI总量
        //   afterTotalSupply: info.afterTotalSupply // 变基后sNEI总量
        // };

        if (info.beforeTotalSupply != 0) {
          epochRate =
            (info.afterTotalSupply - info.beforeTotalSupply) /
            info.beforeTotalSupply;
        }
      }

      return epochRate;
    } catch (err) {
      console.log(err);
      return;
    }
  }
}

export default sNEIErc20Service;
