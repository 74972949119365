import Web3 from "web3";
import BigNumber from "bignumber.js";
import { noWallet, unit, nToBN } from "../utils/index";
import config from "../config";
import DistributorABI from './abi/MerkleDistributor.json'
import Erc20 from "./abi/IERC20";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});


class MerkleDistributor {
  web3;
  constructor() {
    try {
      const web3 = window.web3;
      window.ethereum.enable();
      this.currentWeb3 = new Web3(web3.currentProvider);

      this.inviteContract = new this.currentWeb3.eth.Contract(DistributorABI, config.inviteMerkleDistributor);
    } catch (e) {
      console.log(e.message);
    }
  }

  // 获取当前钱包地址
  async getCurrentAccount() {
    try {
      return window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(res => {
          // console.log(res);
          return res[0];
        });
    } catch (e) {
      console.log(e.message);
    }
  }

  // 获取当前钱包地址2
  async getCurrentAccount2() {
    try {
      let account = ''
      await this.currentWeb3.eth.getAccounts().then(res => {
        account = res[0]
      })
      return account
    } catch (e) {
      console.log(e.message);
    }
  }

  // 领取邀请奖励
  async inviteClaim(markle_index, account, reward_un_claim, markle_prove) {
    try {
      const addr = await this.getCurrentAccount()
      console.log('%c addr', 'color: #00A0E9; font-size: 26px; font-weight: blod;', addr);
      return await this.inviteContract.methods.claim(markle_index, account, nToBN(reward_un_claim, networkConfig.usdtToken.decimals), markle_prove).send({ from: addr })
    } catch (err) {
      console.log('%c er', 'color: #00A0E9; font-size: 26px; font-weight: blod;', err);
      return err;
    }
  }
}

export default MerkleDistributor;
