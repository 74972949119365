import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useMedia } from "react-use";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import config from '../../../config'
import "./index.scss";
import CModal from "../../common/CModal";

import Metamask from '../../../assets/images/Metamask.png'
import { ContractServe } from "../../../contracts";

const LinkModal = props => {
  const { t } = useTranslation();
  const history = useHistory()
  const { activate } = useWeb3React()
  const below960 = useMedia("(max-width: 960px)");
  const [account, setAccount] = useState('')

  useEffect(async () => {
    const account1 = await ContractServe.getCurrentAccount()
    setAccount(account1)
  }, [])

  const link = async () => {
    if (!(window.web3 || window.ethereum)) {
      window.open("https://metamask.io/");
      props.setVisible(false);
      return;
    }
    props.setVisible(false)
    if (account) {
      props.setAccount(account)
      return;
    }
    // if (window.ethereum) {
    //   window.ethereum
    //     .request({
    //       method: "wallet_switchEthereumChain",
    //       params: [
    //         {
    //           // 56
    //           chainId: Web3.utils.toHex(config.networkId),
    //         }
    //       ]
    //     })
    //     .then(() => {
    //       props.setVisible(false);
    //     });
    // ContractServe.getCurrentAccount().then(account1 => {
    //   props.setAccount(account1)
    //   props.setVisible(false)
    //   setAccount(account1)
    // })
    // }
    await activate(config.injected)
  };

  function close() {
    props.setVisible(false)
  }

  return <CModal
    title={t('text73')}
    footer={null}
    centered
    visible={props.visible}
    afterClose={close}
    onOk={() => props.setVisible(false)}
    onCancel={() => props.setVisible(false)}
    width={403}
  >
    <div className="link-content">
      <p onClick={link}><span>{!(window.web3 || window.ethereum) ? t('text74') : 'Metamask'}</span><img src={Metamask} alt="" /></p>
    </div>
  </CModal>
};

export default LinkModal;
