import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import MarqSwapABI from "./abi/MarqSwap.json";
import config from "../config";
import { nToBN } from "../utils";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class MarqSwapService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.marqSwapContract = new this.web3.eth.Contract(
        MarqSwapABI,
        config.MarqSwap
      );
    } catch (e) {
      console.log(e.message);
    }
  }



  async getUserInfo() {
    const account = await this.getCurrentAccount();
    try {
      return await this.marqSwapContract.methods
        .getUserInfo(account)
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async swap(amount) {
    const account = await this.getCurrentAccount();
    try {
      return await this.marqSwapContract.methods
        .swap(amount)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  async claim() {
    const account = await this.getCurrentAccount();
    try {
      return await this.marqSwapContract.methods
        .claim()
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }


}

export default MarqSwapService;
