import envDev from "./env-dev.js"; // 开发环境配置
import envTest from "./env-test.js"; // 测试环境配置
import envPre from "./env-pre.js"; // 预发布环境配置
import envProd from "./env-prod.js"; // 
import envOnline from "./env-online.js"; // 线上环境配置（生成环境）
import devMagnify from "./env-dev-magnify.js";
import { InjectedConnector } from "@web3-react/injected-connector";

const ENV = process.env.REACT_APP_ENV || "dev"; // dev, pre, online
let config = {};
if (ENV == "dev") {
  config = envDev;
  // config = envPre;
} else if (ENV == "test") {
  config = envTest;
} else if (ENV == "pre") {
  config = envPre;
} else if (ENV == "prod") {
  config = envProd;
} else if (ENV == "online") {
  config = envOnline;
} else if (ENV == "dev-magnify") {
  config = devMagnify;
}

const baseConfig = {
  apiUrl: config.baseUrl
};

let injected;
let supportedChainIds = [];
if (ENV === "dev") {
  supportedChainIds = [config.networkId];
  injected = new InjectedConnector({
    supportedChainIds
  });
} else if (ENV === "test") {
  // supportedChainIds = [97];
  supportedChainIds = [config.networkId];
  injected = new InjectedConnector({
    supportedChainIds
  });
} else if (ENV === "pre") {
  // supportedChainIds = [56];
  supportedChainIds = [config.networkId];
  injected = new InjectedConnector({
    supportedChainIds
  });
} else if (ENV === "prod") {
  supportedChainIds = [config.networkId];
  injected = new InjectedConnector({
    supportedChainIds
  });
} else if (ENV === "online") {
  // supportedChainIds = [56];
  supportedChainIds = [config.networkId];
  injected = new InjectedConnector({
    supportedChainIds
  });
} else if (ENV === "dev-magnify") {
  // supportedChainIds = [97];
  supportedChainIds = [config.networkId];
  // supportedChainIds = [97];
  // supportedChainIds = [56];
  // supportedChainIds = [3];
  injected = new InjectedConnector({
    supportedChainIds
  });
}

export default {
  ...baseConfig,
  ...config,
  injected,
  supportedChainIds,
  env: ENV
};
