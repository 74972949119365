import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import AnnuityDataABI from "./abi/AnnuityDataABI";
import config from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class AnnuityDataService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.annuityDataContract = new this.web3.eth.Contract(
        AnnuityDataABI,
        config.AnnuityData
      );
    } catch (e) {
      console.log(e.message);
    }
  }


  async getAnnuInfo(pool) {
    try {
      const res = await this.annuityDataContract.methods
        .getAnnuInfo(pool)
        .call();
      return res
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  async getInsureInfo(pool, insurID) {
    try {
      return await this.annuityDataContract.methods
        .getInsureInfo(pool, insurID)
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }
  async getPoolData(pool) {
    try {
      return await this.annuityDataContract.methods
        .getPoolData(pool)
        .call();
    } catch (e) {
      console.log(e);
      return e;
    }
  }

}

export default AnnuityDataService;
