import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import MasterChefABI from "./abi/MasterChefABI";

import { nToBN, bNToN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class MasterChefService extends Erc20Service {
  web3;
  masterChefContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.masterChefContract = new this.web3.eth.Contract(
        MasterChefABI,
        networkConfig.masterChefContractAddr
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  // 根据pid,用户地址，查询用户信息
  async userInfo() {
    const account = await this.getCurrentAccount();

    try {
      const pid = 0;
      const userInfo = await this.masterChefContract.methods
        .userInfo(pid, account)
        .call();
      return {
        amount: bNToN(userInfo.amount, networkConfig.lpToken.decimals),
        rewardDebt: userInfo.rewardDebt
      };
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 已挖出
  // 查询用户当前lp挖矿数量,默认传0
  async pendingIsm() {
    const account = await this.getCurrentAccount();

    try {
      const pid = 0;
      const amount = await this.masterChefContract.methods
        .pendingIsm(pid, account)
        .call();
      return bNToN(amount, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 流动性累计
  // 查询用户抵押lp累计挖矿金额
  async userMint() {
    const account = await this.getCurrentAccount();

    try {
      const pid = 0;
      const amount = await this.masterChefContract.methods
        .userMint(account, pid)
        .call();
      return bNToN(amount, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 抵押v2LP解锁ISM
  async deposit(amount) {
    const account = await this.getCurrentAccount();

    try {
      const pid = 0;
      const decimals = await this.getTokenDecimals(
        networkConfig.v2LpToken.address
      );
      amount = nToBN(amount, decimals);
      return await this.masterChefContract.methods
        .deposit(pid, amount)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 赎回v2LP
  async withdraw(amount) {
    const account = await this.getCurrentAccount();

    try {
      const pid = 0;
      return await this.masterChefContract.methods
        .withdraw(pid, amount)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 新版将调整为:通过用户地址和池子id查询用户当前解锁时间
  async getUnlockTime() {
    const account = await this.getCurrentAccount();

    try {
      const pid = 0;
      // return await this.masterChefContract.methods
      //   .unLockTime(account, pid)
      //   .call();
      const unlockTime = await this.masterChefContract.methods
        .unLockTime(account, pid)
        .call();
      return unlockTime;
    } catch (e) {
      console.log(e);
      return;
    }
  }
}

export default MasterChefService;
