import { Contract } from "./contract";
import Erc20Service from "./Erc20Service";
import NeiBondService from "./NeiBondService";
import NeiVaultService from "./NeiVaultService";
import SNEIErc20Service from "./sNEIErc20Service";
import InsuranceService from './InsuranceService'
import RewardPoolService from "./RewardPoolService";
import MasterChefService from "./MasterChefService";
import CpstService from "./CpstService";
import IsmLockPoolService from "./IsmLockPoolService";
import IpstService from './IpstService'
import PendantService from './PendantService'
import MerkleDistributor from './MerkleDistributor'
import RouterV1Service from './RouterV1Service'
import AnnuityDataService from './AnnuityDataService'
import TradingService from './TradingService'
import MarqSwapService from './MarqSwapService'
import IntegralService from './IntegralService'
import MarqueeInscriptionService from './MarqueeInscriptionService'
import BonusService from './BonusService'
import ZenixService from './ZenixService'
import IdoService from './IdoService'
import RewardService from './RewardService'

export const ContractServe = new Contract();
export const erc20Service = new Erc20Service();
export const neiBondService = new NeiBondService();
export const neiVaultService = new NeiVaultService();
export const sNeiErc20Service = new SNEIErc20Service();
export const insuranceService = new InsuranceService();
export const rewardPoolService = new RewardPoolService();
export const masterChefService = new MasterChefService();
export const cpstService = new CpstService();
export const ipstService = new IpstService();
export const ismLockPoolService = new IsmLockPoolService();
export const pendantService = new PendantService();
export const inviteMerkleDistributorbutor = new MerkleDistributor();
export const routerV1Service = new RouterV1Service();
export const annuityDataService = new AnnuityDataService();
export const tradingService = new TradingService();
export const marqSwapService = new MarqSwapService();
export const integralService = new IntegralService();
export const marqueeInscriptionService = new MarqueeInscriptionService();
export const bonusService = new BonusService();
export const zenixService = new ZenixService();
export const idoService = new IdoService();
export const rewardService = new RewardService();
