import Web3 from "web3";
import BigNumber from "bignumber.js";
import Erc20Service from "./Erc20Service";
import insuranceABI from "./abi/InsuranceABI";

import { bNToN, nToBN } from "../utils";
import networkConfig from "../config";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

class InsuranceService extends Erc20Service {
  web3;
  racePoolContract;

  constructor() {
    super();
    try {
      // if (this.web3) return;
      const web3 = window.web3;
      window.ethereum.enable();
      this.web3 = new Web3(web3.currentProvider);

      this.insuranceContract = new this.web3.eth.Contract(
        insuranceABI,
        networkConfig.insuranceContractAddr
      );
    } catch (e) {
      console.log(e.message);
    }
  }

  // 通过hash, 保单，签名，效验数据是否正确，正确返回占用资金，手续费用
  async validateOrder(hash, order, sig) {
    const account = await this.getCurrentAccount();

    try {
      const result = await this.insuranceContract.methods
        .validateOrder(hash, order, sig)
        .call({ from: account });
      return result;
    } catch (e) {
      let code = 100;
      if (e.message.indexOf("execution reverted: address err") > -1) {
        code = 1;
      } else if (e.message.indexOf("execution reverted: make time err") > -1) {
        code = 2;
      } else if (
        e.message.indexOf("execution reverted: the hash has tx") > -1
      ) {
        code = 3;
      } else if (
        e.message.indexOf("execution reverted: not sig address") > -1
      ) {
        code = 4;
      } else if (
        e.message.indexOf("execution reverted: not appove enough") > -1
      ) {
        code = 5;
      } else if (e.message.indexOf("execution reverted: multiple err") > -1) {
        code = 6;
      } else if (e.message.indexOf("execution reverted: pay err") > -1) {
        code = 7;
      } else if (
        e.message.indexOf("execution reverted: order amount err") > -1
      ) {
        code = 8;
      } else if (e.message.indexOf("execution reverted: initOwner err") > -1) {
        code = 9;
      } else if (e.message.indexOf("execution reverted: side value err") > -1) {
        code = 10;
      }

      return {
        code
      };

      // 1. address err：保险合约或者USDT的地址不正确
      // 2. make time err： 保单购买时间错误（保单时间错误）
      // 3. the hash has tx：这个保单已经购买（保单已购买）
      // 4. not sig address: 签名地址错误，不是管理员地址（签名地址错误）
      // 5. not appove enough：usdt授权金额不够（USDT授权金额不足）
      // 6. multiple err: 赔付倍数错误（赔付倍数错误）
      // 7. pay err：资金池不够赔付当前保单（资金池不足）
      // 8. order amount err：保单的赔付金额超过总资金的对每个保单的最大赔付额（超出单笔保单最大限额）
      // 9. initOwner err：当前地址不是购买保单所填的地址（当前地址不正确）
      // 10. side value err: 传入的保单买涨或者买跌的总价值超过占比（超出保单类型最大限额）
    }
  }

  // 购买保单
  async buyInsurance(order, sig) {
    const account = await this.getCurrentAccount();

    try {
      return await this.insuranceContract.methods
        .buyISMInsurance(order, sig)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  // 查询订单累计占用资金池比率，默认返回10000 => 10000/10000 =>1 => 100%
  async takeRate() {
    try {
      const result = await this.insuranceContract.methods.takeRate().call();

      return result / 10000;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 根据产品地址，查询该产品买涨或者买跌的最大占用比率，拿到的值除以10000
  async getProductSideRate(tokenAddress) {
    try {
      const result = await this.insuranceContract.methods
        .getProductSideRate(tokenAddress)
        .call();

      return result / 10000;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 领取保单收益的参数验证
  async validateClaim(hash, claim, sig) {
    const account = await this.getCurrentAccount();

    try {
      const result = await this.insuranceContract.methods
        .validateClaim(hash, claim, sig)
        .call({ from: account });
      return result;
    } catch (e) {
      console.log(e.message);

      let code = 100;
      if (e.message.indexOf("execution reverted: not sig address") > -1) {
        code = 1;
      } else if (e.message.indexOf("execution reverted: claim time err") > -1) {
        code = 2;
      } else if (
        e.message.indexOf(
          "execution reverted: have claim or not have reward or not set"
        ) > -1
      ) {
        code = 3;
      } else if (
        e.message.indexOf("execution reverted: tokenID not yours") > -1
      ) {
        code = 4;
      } else if (e.message.indexOf("execution reverted: orderID err") > -1) {
        code = 5;
      }

      // 1. not sig address： 签名地址不是当前的设置的签名地址（签名地址错误）
      // 2. claim time err：领取时间为未来时间，或者领取时间上链后超过延迟时间（领取时间错误）
      // 3. have claim or not have reward or not set：已经领取奖励或者管理员还未结算（已领取或未结算）
      // 4. tokenID not yours：不是tokenID的持有人（持有人错误）
      // 5. orderID err： orderID不存在（orderID不存在）

      return {
        code
      };
    }
  }

  // 领取保单收益
  // async claimUserOrder(tokenID) {
  //   const account = await this.getCurrentAccount();
  //   try {
  //     return await this.insuranceContract.methods
  //       .claimUserOrder(tokenID)
  //       .send({ from: account });
  //   } catch (e) {
  //     return;
  //   }
  // }
  async claimUserOrder(claim, sig) {
    const account = await this.getCurrentAccount();
    try {
      return await this.insuranceContract.methods
        .claimUserOrder(claim, sig)
        .send({ from: account });
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 交易累计
  // 查询用户保单挖矿金额
  async orderMint() {
    const account = await this.getCurrentAccount();

    try {
      const amount = await this.insuranceContract.methods
        .orderMint(account)
        .call();
      return bNToN(amount, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 通过如下参数，计算该保单买入后是否超过资金池设置占比，默认为80%
  // 返回:0 表示占比超过80%
  // uint256 amount, 	// 购买保单扣除手续费用后，注入资金池的实际金额
  // uint256 newOrderValue, // 购买保单时保单的价值，即购买保单时的花费
  // uint256 totalOrderValue // 当前所有保单的总价值
  async getCanPayAmount(orderValue, totalOrderValue) {
    try {
      const feeRate = await this.manageFeeRate();

      const amount = BigNumber(orderValue)
        .times(1 - feeRate)
        .toString();
      const newOrderValue = orderValue;


      const result = await this.insuranceContract.methods
        .getCanPayAmount(amount)
        // .getCanPayAmount(amount, newOrderValue, totalOrderValue)
        .call();
      return result;
      // return bNToN(amount, 18);
    } catch (e) {
      console.log(e);
      return;
    }
  }

  // 查询购买保单收费比率，默认返回1000 => 1000/10000 =>0.1 => 10%
  // manageFeeRate() returns(uint256)
  async manageFeeRate() {
    try {
      const result = await this.insuranceContract.methods
        .manageFeeRate()
        .call();
      return result / 10000;
    } catch (e) {
      console.log(e);
      return;
    }
  }
}

export default InsuranceService;
